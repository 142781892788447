import { useCallback } from 'react';
import isEqual from 'hs-lodash/isEqual';
import useCrmObjectTypeListingPagination from 'framework-listing-lib/internal/hooks/useCrmObjectTypeListingPagination';
import useCrmObjectTypeListingSort from 'framework-listing-lib/internal/hooks/useCrmObjectTypeListingSort';
import { useTableColumns } from 'framework-listing-lib/internal/Table/providers/TableColumnsProvider';
import { useHasViews, useSetViewColumns, useViewColumns, useViewUpdateState } from 'framework-listing-lib/internal/ViewTabs/hooks/useViewProps';
import useCurrentView from 'framework-listing-lib/hooks/useCurrentView';
import useCrmObjectTypeTracking from 'framework-listing-lib/internal/hooks/useCrmObjectTypeTracking';
import useMutateViews from 'framework-listing-lib/internal/ViewTabs/hooks/useMutateViews';
import { getViewId } from 'framework-listing-lib/internal/ViewTabs/utils/views';
export default function useFrameworkDataTableEventHandlers(data) {
  const hasViews = useHasViews();
  const currentView = useCurrentView();
  const {
    trackCrmTableInteraction
  } = useCrmObjectTypeTracking();
  const {
    saveDefaultView
  } = useMutateViews();
  const [, setViewUpdateState] = useViewUpdateState();
  const {
    onPageChange,
    onPageSizeChange
  } = useCrmObjectTypeListingPagination(data ? data.total : 0);
  const {
    onSetSort
  } = useCrmObjectTypeListingSort();
  const columns = useViewColumns();
  const setColumns = useSetViewColumns();
  const {
    onResizeEnd
  } = useTableColumns();
  const getRowId = useCallback(({
    objectId
  }) => String(objectId), []);
  const handlePageChange = useCallback(({
    nextPageIndex
  }) => onPageChange(nextPageIndex), [onPageChange]);
  const handlePageSizeChange = useCallback(({
    nextPageSize
  }) => onPageSizeChange(nextPageSize), [onPageSizeChange]);
  const handleSortsChange = useCallback(({
    nextSorts: [{
      columnName,
      direction
    }]
  }) => {
    onSetSort([{
      id: columnName,
      desc: direction === 1
    }]);
  }, [onSetSort]);
  const handleColumnWidthChange = useCallback(({
    columnName,
    nextWidth
  }) => {
    onResizeEnd(columnName, nextWidth);
  }, [onResizeEnd]);
  const handleColumnOrderChange = useCallback(({
    columnName,
    nextIndex
  }) => {
    const filteredCols = columns.filter(name => name !== columnName);
    filteredCols.splice(nextIndex, 0, columnName);
    setColumns(filteredCols);
    if (hasViews && currentView) {
      trackCrmTableInteraction({
        action: 'reorder-view-columns',
        isDefaultView: currentView.type === 'DEFAULT',
        viewId: getViewId(currentView)
      });
      if (currentView.type === 'DEFAULT') {
        saveDefaultView(Object.assign({}, currentView, {
          columns: filteredCols.map((col, index) => ({
            name: col,
            order: index + 1
          })),
          defaultColumns: filteredCols.map((col, index) => ({
            name: col,
            order: index + 1
          }))
        })).then(() => {}).catch(() => {});
      } else {
        setViewUpdateState(prev => Object.assign({}, prev, {
          changedColumns: !isEqual(filteredCols, currentView.columns.map(col => col.name))
        }));
      }
    }
  }, [columns, currentView, hasViews, saveDefaultView, setColumns, setViewUpdateState, trackCrmTableInteraction]);
  return {
    getRowId,
    handleColumnOrderChange,
    handleColumnWidthChange,
    handlePageChange,
    handlePageSizeChange,
    handleSortsChange
  };
}