import { createSelector } from 'reselect';
import { DRAG_DROP_CREATION_HREF, DRAG_DROP_ONBOARDING_CREATION_HREF, CLASSIC_CREATION_HREF } from 'EmailData/constants/emailCreation';
import { getCanCreateClassicEmail, getCanCreateDragDropEmail, getCanSelectMoreThanRegularEmail, getHasEmailBlogOnlyAccess, getHasOnboarding, getIsUngatedForContextualAutomation
// @ts-expect-error untyped module
} from 'EmailData/selectors/user';
export const getShouldShowEmailCreationModal = createSelector([getCanCreateDragDropEmail, getCanSelectMoreThanRegularEmail, getHasEmailBlogOnlyAccess, getIsUngatedForContextualAutomation], (canCreateDragDropEmail, canSelectMoreThanRegularEmail, isUngatedForEmailBlogOnlyAccess, isUngatedForContextualAutomation) => {
  return canCreateDragDropEmail && (canSelectMoreThanRegularEmail || isUngatedForEmailBlogOnlyAccess || isUngatedForContextualAutomation);
});
export const getShouldCreateOnboardingEmail = createSelector([(__, {
  autoStartOnboarding
} = {}) => autoStartOnboarding, getHasOnboarding], (autoStartOnboarding, hasOnboarding) => autoStartOnboarding && hasOnboarding);
export const getEditorHref = createSelector([getCanCreateDragDropEmail, getCanCreateClassicEmail, getShouldCreateOnboardingEmail], (canCreateDragDropEmail, canCreateClassicEmail, shouldCreateOnboardingEmail) => {
  if (canCreateDragDropEmail) {
    if (shouldCreateOnboardingEmail) {
      return DRAG_DROP_ONBOARDING_CREATION_HREF;
    }
    return DRAG_DROP_CREATION_HREF;
  }
  if (canCreateClassicEmail) {
    return CLASSIC_CREATION_HREF;
  }
  return null;
});
export const getMergedEditorHref = createSelector([getShouldCreateOnboardingEmail], shouldCreateOnboardingEmail => {
  if (shouldCreateOnboardingEmail) {
    return DRAG_DROP_ONBOARDING_CREATION_HREF;
  }
  return DRAG_DROP_CREATION_HREF;
});