/**
The PropertyInput component is a proxy component that determines, based on the
props passed to it, which type of Input component should be rendered for the
property.
If all the inputs were imported into that component they would all needed to be
evaluated by the JS engine even though only a handful may actually be necessary.
Instead, the inputs can be individually loaded by require in these getters.
Require is used because it allows the components to all be bundled into the
webpack bundle, but delays the code evaluation until the first time the module
is 'require'd. So using the getters in this file, the input components are
dynamically evaluated as they are needed, resulting in a significant performance
boost.
***/

import Requirable from '../utils/Requirable';

/* eslint-disable */

export const RequirablePropertyInputHidden = Requirable({
  loader: () => {
    const mod = require('./PropertyInputHidden');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputHighlySensitive = Requirable({
  loader: () => {
    const mod = require('./PropertyInputHighlySensitive');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputExternalOption = Requirable({
  loader: () => {
    const mod = require('./PropertyInputExternalOption');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputPercentage = Requirable({
  loader: () => {
    const mod = require('./PropertyInputPercentage');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputDuration = Requirable({
  loader: () => {
    const mod = require('./PropertyInputDuration');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputSettlementCurrency = Requirable({
  loader: () => {
    const mod = require('./PropertyInputSettlementCurrency');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputPrice = Requirable({
  loader: () => {
    const mod = require('./PropertyInputPrice');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputHomeCurrencyV2 = Requirable({
  loader: () => {
    const mod = require('./PropertyInputHomeCurrencyV2');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputMonthPeriod = Requirable({
  loader: () => {
    const mod = require('./PropertyInputMonthPeriod');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputHtml = Requirable({
  loader: () => {
    const mod = require('./PropertyInputHtml');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputPhoneWrapper = Requirable({
  loader: () => {
    const mod = require('./PropertyInputPhoneWrapper');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputDealExchangeRateWrapper = Requirable({
  loader: () => {
    const mod = require('./PropertyInputDealExchangeRateWrapper');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputNumber = Requirable({
  loader: () => {
    const mod = require('./PropertyInputNumber');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputMultiCurrencyCurrencyCodeV2 = Requirable({
  loader: () => {
    const mod = require('./PropertyInputMultiCurrencyCurrencyCodeV2');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputTaxCategory = Requirable({
  loader: () => {
    const mod = require('./PropertyInputTaxCategory');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputPriority = Requirable({
  loader: () => {
    const mod = require('./PropertyInputPriority');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputKnownGuid = Requirable({
  loader: () => {
    const mod = require('./PropertyInputKnownGuid');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputMultipleEmailV2 = Requirable({
  loader: () => {
    const mod = require('./PropertyInputMultipleEmailV2');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputSubscriptionStatus = Requirable({
  loader: () => {
    const mod = require('./PropertyInputSubscriptionStatus');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputPaymentStatus = Requirable({
  loader: () => {
    const mod = require('./PropertyInputPaymentStatus');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputFiles = Requirable({
  loader: () => {
    const mod = require('./PropertyInputFiles');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputFilesV2 = Requirable({
  loader: () => {
    const mod = require('./PropertyInputFilesV2');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputReadOnly = Requirable({
  loader: () => {
    const mod = require('./PropertyInputReadOnly');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputEnum = Requirable({
  loader: () => {
    const mod = require('./PropertyInputEnum');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputEmailV2 = Requirable({
  loader: () => {
    const mod = require('./PropertyInputEmailV2');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputMultipleDomain = Requirable({
  loader: () => {
    const mod = require('./PropertyInputMultipleDomain');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputCloseCreateDate = Requirable({
  loader: () => {
    const mod = require('./PropertyInputCloseCreateDate');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputDate = Requirable({
  loader: () => {
    const mod = require('./PropertyInputDate');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputExpandableText = Requirable({
  loader: () => {
    const mod = require('./PropertyInputExpandableText');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputTextArea = Requirable({
  loader: () => {
    const mod = require('./PropertyInputTextArea');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputCheckbox = Requirable({
  loader: () => {
    const mod = require('./PropertyInputCheckbox');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputPipelineV2 = Requirable({
  loader: () => {
    const mod = require('./PropertyInputPipelineV2');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputPipelineStageV2 = Requirable({
  loader: () => {
    const mod = require('./PropertyInputPipelineStageV2');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputUrl = Requirable({
  loader: () => {
    const mod = require('./PropertyInputUrl');
    return mod.default || mod;
  }
});
export const RequirablePropertyInputDateTime = Requirable({
  loader: () => {
    const mod = require('./PropertyInputDateTime');
    return mod.default || mod;
  }
});

/* eslint-enable */