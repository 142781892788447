import { CRM_SUITE_STARTER_BUNDLE, MARKETING_HUB_STARTER } from 'self-service-api/constants/ApiNames';
import { MARKETING_STARTER, SUITE_STARTER } from 'self-service-api/constants/UpgradeProducts';
import { POINT_TYPES } from '../ums/types';
import { UpgradePointStatus } from '../ums/utils';

// Hardcoding to simplify. This matches this config: https://tools.hubteamqa.com/ums/upgrade-points/mc-0653-banner
export const MC0653_BANNER_UPGRADE_CONFIG = {
  upgradeKey: 'mc-0653-banner',
  apiNames: [CRM_SUITE_STARTER_BUNDLE, MARKETING_HUB_STARTER],
  upgradeProducts: [SUITE_STARTER, MARKETING_STARTER],
  featureStoreFeature: 'emailMarketing',
  team: 'hs-monetization-core-fe',
  featureTranslationKey: 'mc0653',
  pointType: POINT_TYPES.USAGE_LIMIT_BANNER,
  status: UpgradePointStatus.ACTIVE,
  primaryColor: 'DATA',
  title: null,
  subtitle: null,
  visualType: null,
  visualKey: null,
  pointLocation: '',
  recommendationResponse: null
};

// Hardcoding to simplify. This matches this config: https://tools.hubteamqa.com/ums/upgrade-points/mc-0653-email-sends-counter?isNew=true
export const MC0653_EMAIL_SENDS_UPGRADE_CONFIG = {
  upgradeKey: 'mc-0653-email-sends-counter',
  apiNames: [CRM_SUITE_STARTER_BUNDLE, MARKETING_HUB_STARTER],
  upgradeProducts: [SUITE_STARTER, MARKETING_STARTER],
  featureStoreFeature: 'emailMarketing',
  team: 'hs-monetization-core-fe',
  featureTranslationKey: 'mc0653',
  pointType: POINT_TYPES.USAGE_LIMIT_BANNER,
  status: UpgradePointStatus.ACTIVE,
  primaryColor: 'DATA',
  title: null,
  subtitle: null,
  visualType: null,
  visualKey: null,
  pointLocation: '',
  recommendationResponse: null
};

// Hardcoding to simplify. This matches this config: https://tools.hubteamqa.com/ums/upgrade-points/mc-0653-email-sends-counter?isNew=true
export const MC0653_SENDING_OPTIONS_UPGRADE_CONFIG = {
  upgradeKey: 'mc-0653-sending-options',
  apiNames: [CRM_SUITE_STARTER_BUNDLE, MARKETING_HUB_STARTER],
  upgradeProducts: [SUITE_STARTER, MARKETING_STARTER],
  featureStoreFeature: 'emailMarketing',
  team: 'hs-monetization-core-fe',
  featureTranslationKey: 'mc0653',
  pointType: POINT_TYPES.USAGE_LIMIT_BANNER,
  status: UpgradePointStatus.ACTIVE,
  primaryColor: 'DATA',
  title: null,
  subtitle: null,
  visualType: null,
  visualKey: null,
  pointLocation: '',
  recommendationResponse: null
};