import Raven from 'raven-js';
import emptyFunction from 'react-utils/emptyFunction';
import { FETCH_LISTS, FETCH_LISTS_REQUEST } from 'EmailData/actions/actionTypes';
import { fetchBatchListsRequest } from 'EmailData/api/recipients';
import { recipientsListsMapper } from 'EmailData/utils/recipientsListsMapper';
export function fetchLists(listIds) {
  if (listIds === null || !listIds.length) {
    return emptyFunction;
  }
  return dispatch => {
    dispatch({
      type: FETCH_LISTS_REQUEST
    });
    const promise = fetchBatchListsRequest(listIds);
    return promise.then(resultPayload => {
      const processedResultPayload = {};
      processedResultPayload.lists = resultPayload.results.map(list => recipientsListsMapper(list));
      return Promise.resolve(dispatch({
        type: FETCH_LISTS,
        payload: processedResultPayload
      }));
    }, err => {
      return Promise.resolve(dispatch({
        type: FETCH_LISTS,
        error: true,
        payload: err
      }));
    }).catch(e => Raven.captureException(e));
  };
}