// @ts-expect-error not migrated to TS yet
import PortalHealthStatus from 'EmailData/models/PortalHealthStatus';
import Routes from 'ContentUtils/Routes';
import KNOWLEDGE_ARTICLE_ROUTES from './knowledgeRoutes';
export const getLangRecoveryKey = key => `EmailData.precog.recovery.${key}`;
export const OPT_OUT_ACTION_NAME = 'SUSPENSION_RECOVERY::OPT_OUT_LIST';
const generateActions = {
  checkYourLists: () => ({
    action: {
      url: Routes.lists(),
      use: 'link'
    },
    button: getLangRecoveryKey('actions.importList'),
    link: getLangRecoveryKey('links.checkLists'),
    linkRoute: KNOWLEDGE_ARTICLE_ROUTES.CONTACT_LIST_TO_USE,
    text: getLangRecoveryKey('steps.checkLists'),
    title: getLangRecoveryKey('title.checkLists')
  }),
  importOptOutList: () => ({
    action: {
      name: OPT_OUT_ACTION_NAME,
      use: 'action'
    },
    button: getLangRecoveryKey('actions.importList'),
    link: getLangRecoveryKey('links.optOut'),
    linkRoute: KNOWLEDGE_ARTICLE_ROUTES.OPT_OUT_LIST,
    text: getLangRecoveryKey('steps.optOut'),
    title: getLangRecoveryKey('title.optOut')
  }),
  runPermissionPassCampaign: () => ({
    link: getLangRecoveryKey('links.runPermissionPass'),
    linkRoute: KNOWLEDGE_ARTICLE_ROUTES.PERMISSION_PASS_CAMPAIGN,
    text: getLangRecoveryKey('steps.runPermissionPass'),
    title: getLangRecoveryKey('title.runPermissionPass')
  }),
  removeStaleContacts: stepSecondKey => ({
    action: {
      url: Routes.lists(),
      use: 'link'
    },
    button: getLangRecoveryKey('actions.goToLists'),
    link: getLangRecoveryKey('links.cleanLists'),
    linkRoute: KNOWLEDGE_ARTICLE_ROUTES.CLEANUP_CONTACT_LISTS,
    text: getLangRecoveryKey(`steps.${stepSecondKey}`),
    title: getLangRecoveryKey(`title.${stepSecondKey}`)
  })
};
const generateDefaultRecoveryFlow = (isUnsubscribesReason = false) => [generateActions.importOptOutList(), generateActions.removeStaleContacts(isUnsubscribesReason ? 'removeContacts' : 'removeStale')];
const generateSpamRecoveryFlow = () => [generateActions.checkYourLists(), generateActions.runPermissionPassCampaign()];
const RECOVERY_CONFIG = {
  [PortalHealthStatus.HEALTH_STATES.PROBATION_1]: {
    levelKey: 'probation',
    steps: {
      [PortalHealthStatus.REASON_TYPES.DEFAULT]: generateDefaultRecoveryFlow(),
      [PortalHealthStatus.REASON_TYPES.SPAM]: generateSpamRecoveryFlow(),
      [PortalHealthStatus.REASON_TYPES.UNSUBSCRIBES]: generateDefaultRecoveryFlow(true)
    }
  },
  [PortalHealthStatus.HEALTH_STATES.PROBATION_2]: {
    levelKey: 'probation',
    steps: {
      [PortalHealthStatus.REASON_TYPES.DEFAULT]: generateDefaultRecoveryFlow(),
      [PortalHealthStatus.REASON_TYPES.SPAM]: generateSpamRecoveryFlow(),
      [PortalHealthStatus.REASON_TYPES.UNSUBSCRIBES]: generateDefaultRecoveryFlow(true)
    }
  },
  [PortalHealthStatus.HEALTH_STATES.PROBATION_3]: {
    levelKey: 'preSuspend',
    steps: {
      [PortalHealthStatus.REASON_TYPES.DEFAULT]: generateDefaultRecoveryFlow(),
      [PortalHealthStatus.REASON_TYPES.SPAM]: generateSpamRecoveryFlow()
    }
  },
  [PortalHealthStatus.HEALTH_STATES.SUSPENDED]: {
    levelKey: 'suspension',
    steps: {
      [PortalHealthStatus.REASON_TYPES.DEFAULT]: generateDefaultRecoveryFlow(false),
      [PortalHealthStatus.REASON_TYPES.UNSUBSCRIBES]: generateDefaultRecoveryFlow(true)
    }
  }
};
export const getLevelLangKey = state => RECOVERY_CONFIG[state].levelKey;
export const getStateLangKey = (state, key) => {
  const levelKey = RECOVERY_CONFIG[state].levelKey;
  return `EmailData.precog.${levelKey}.${key}`;
};
export const getAlertType = state => state === PortalHealthStatus.HEALTH_STATES.PROBATION_1 ? 'warning' : 'danger';
export const getRecoverySteps = (state, reason) => {
  const recoverySteps = RECOVERY_CONFIG[state].steps;
  return (
    // @ts-expect-error cannot be used to index type
    recoverySteps[reason] || recoverySteps[PortalHealthStatus.REASON_TYPES.DEFAULT]
  );
};