'use es6';

import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import EmailType from 'EmailData/enums/EmailType';
import { AB_EMAIL_WINNER, DETAIL_AB_TYPES } from 'EmailData/utils/details/detailTypeUtil';
import currentEmailDetails from 'EmailData/selectors/details/current/currentEmailDetails';
import currentEmailType from 'EmailData/selectors/details/current/currentEmailType';
import isEqual from 'hs-lodash/isEqual';
function getAbOptions(emailDetails) {
  const isDraft = emailDetails.isDraft();
  const aId = !isDraft ? emailDetails.getAbMasterCampaignId() : emailDetails.primaryEmail.id;
  const bId = !isDraft ? emailDetails.getAbVariantCampaignId() : emailDetails.variantEmail.id;
  const [primaryKey, primaryValue] = getAbOption(emailDetails, aId, DETAIL_AB_TYPES.PRIMARY);
  const [variantKey, variantValue] = getAbOption(emailDetails, bId, DETAIL_AB_TYPES.VARIANT);
  return ImmutableMap({
    [primaryKey]: primaryValue,
    [variantKey]: variantValue
  });
}
function getAbOption(emailDetails, emailId, baseKey) {
  if (emailDetails.isEmailWinner(emailId)) {
    return [`${baseKey}Winner`, AB_EMAIL_WINNER];
  } else if (emailDetails.isEmailFallback(emailId)) {
    return [`${baseKey}Fallback`, AB_EMAIL_WINNER];
  }
  return [baseKey, emailId];
}
function getAbAutomatedOptions(emailDetails) {
  //Automated AB could invert variant and master on win
  //Winning matching relies on campaign id instead of email id here
  const aCampaignId = emailDetails.getLastTestPrimaryEmailCampaignId();
  const bCampaignId = emailDetails.getLastTestVariantEmailCampaignId();
  if (emailDetails.hasWinner()) {
    const campaignWinningIds = emailDetails.getWinningCampaignIds();
    let winnerOnlyCampaignId = aCampaignId;
    let fallbackCampaignId = bCampaignId;
    let winnerType = DETAIL_AB_TYPES.PRIMARY;
    let fallbackType = DETAIL_AB_TYPES.VARIANT;

    // Assume that A is the default winner, if that is not the case
    // We update the winner campaign and type to B
    if (emailDetails.isEmailWinner('B')) {
      winnerOnlyCampaignId = bCampaignId;
      fallbackCampaignId = aCampaignId;
      winnerType = DETAIL_AB_TYPES.VARIANT;
      fallbackType = DETAIL_AB_TYPES.PRIMARY;
    }
    // campaignWinningIds include id of winner before and after winning
    // we filter out current winning id in order to get the before winning id
    const winnerBeforeWinningCampaignId = campaignWinningIds.filter(id => id !== winnerOnlyCampaignId)[0];
    const [winnerVersionKey, winnerVersionValue] = getAbAutomatedOption(emailDetails, campaignWinningIds, winnerType, 'Winner');
    const [winnerOnlyKey, winnerOnlyValue] = getAbAutomatedOption(emailDetails, winnerOnlyCampaignId, winnerType, 'WinnerOnly');
    const [winnerBeforeWinningOnlyKey, winnerBeforeWinningOnlyValue] = getAbAutomatedOption(emailDetails, winnerBeforeWinningCampaignId, winnerType, 'BeforeWinning');
    const [variantKey, variantValue] = getAbAutomatedOption(emailDetails, fallbackCampaignId, fallbackType, '');
    return ImmutableMap({
      [winnerVersionKey]: winnerVersionValue,
      [winnerOnlyKey]: winnerOnlyValue,
      [winnerBeforeWinningOnlyKey]: winnerBeforeWinningOnlyValue,
      [variantKey]: variantValue
    });
  } else {
    const [primaryKey, primaryValue] = getAbAutomatedOption(emailDetails, aCampaignId, DETAIL_AB_TYPES.PRIMARY);
    const [variantKey, variantValue] = getAbAutomatedOption(emailDetails, bCampaignId, DETAIL_AB_TYPES.VARIANT);
    return ImmutableMap({
      [primaryKey]: primaryValue,
      [variantKey]: variantValue
    });
  }
}
function getAbAutomatedOption(emailDetails, campaignId, baseKey, langSubKey = '') {
  // In case of automated A/B email we get an array of ids as campaignId.
  // This array include both campaignId of version (before and after winning)
  // hence its better to use isEqual here.
  if (isEqual(campaignId, emailDetails.getWinningCampaignIds())) {
    return [`${baseKey}${langSubKey}`, AB_EMAIL_WINNER];
  }
  return [`${baseKey}${langSubKey}`, campaignId];
}
function getBatchChildren(emailDetails) {
  if (emailDetails.isEmpty() || !emailDetails.get('emailCampaignSends') || emailDetails.get('emailCampaignSends').size <= 1) {
    return ImmutableMap({});
  }
  return emailDetails.get('emailCampaignSends').reduce((lookup, item) => lookup.set(item.get('id'), item), ImmutableMap({}));
}
const sendChildren = createSelector([currentEmailDetails, currentEmailType], (emailDetails, emailType) => {
  switch (emailType) {
    case EmailType.AB_EMAIL:
      return getAbOptions(emailDetails);
    case EmailType.AUTOMATED_AB_EMAIL:
      return getAbAutomatedOptions(emailDetails);
    case EmailType.RSS_EMAIL:
    case EmailType.BLOG_EMAIL:
      return emailDetails.blogRssEmails;
    case EmailType.LOCALTIME_EMAIL:
      return emailDetails.sendChildren;
    case EmailType.BATCH_EMAIL:
      return getBatchChildren(emailDetails);
    default:
      return ImmutableMap({});
  }
});
export const successfulEmailSendsCount = createSelector([sendChildren], sentEmails => {
  return sentEmails.filter(email => email.status === 'DONE').count();
});
export default sendChildren;