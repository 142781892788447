import { KOALA } from 'HubStyleTokens/colors';
import styled from 'styled-components';

// A simple skeleton ui for text which is currently loading; shows a gray bar.
// It's approximately the height of average text in the current font size (0.8em),
// so it looks sort of like text when you squint.
//
//   <SkeletonText />
//   <SkeletonText width={80} />
//
// Reused from WorkflowsReactUI https://git.hubteam.com/HubSpot/WorkflowsReactUI/blob/master/static/js/platform/components/common/SkeletonText.js/

const getSize = value => typeof value === 'number' ? `${value}px` : value;
const SkeletonText = styled.div.withConfig({
  displayName: "SkeletonText",
  componentId: "sc-1b7uwd3-0"
})(["background-color:", ";border-radius:999px;display:inline-block;height:0.8em;width:", ";"], ({
  backgroundColor
}) => backgroundColor, ({
  width
}) => getSize(width));
SkeletonText.defaultProps = {
  backgroundColor: KOALA,
  width: '6em'
};
export default SkeletonText;