// @ts-expect-error not migrated to ts
import { teamsAreFilterable } from 'ContentUtils/helpers/TeamsHelpers';
// @ts-expect-error not migrated to ts
import { getHasUsersTeamsConfig } from 'EmailData/selectors/user';
import { createSelector } from 'reselect';
import { getTeams, getTeamsRequestSucceeded } from 'ContentData/selectors/teams';
import { buildTeamNameById } from 'EmailData/utils/teams';
export const getTeamsNameById = createSelector([getTeams, getTeamsRequestSucceeded], (teams, isTeamsFetchSuccessful) => {
  return isTeamsFetchSuccessful ? buildTeamNameById(teams) : {};
});
export const showEmailContentPartitioning = createSelector([getHasUsersTeamsConfig, getTeams], (hasUsersTeamsConfig, teams) => hasUsersTeamsConfig && teamsAreFilterable(teams));