
(function() {
  var result = { translations: { "en": null } };
  result.translationsLoaded = {};
  result.translationsAvailable = {
  "email-management-lib": {
    "da": "static-1.8728",
    "de": "static-1.8728",
    "en": "static-1.8728",
    "es": "static-1.8728",
    "fi": "static-1.8728",
    "fr": "static-1.8728",
    "it": "static-1.8728",
    "ja": "static-1.8728",
    "nl": "static-1.8728",
    "no": "static-1.8728",
    "pl": "static-1.8728",
    "pt-br": "static-1.8728",
    "sv": "static-1.8728",
    "zh-tw": "static-1.8728"
  }
};
  hns('I18n', result);

})();


