/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { trackDashboardInteraction } from 'EmailComponents/utils/tracking';
import { SELECT_FOLDER_ROW, SET_TABLE_OFFSET, SORT_TABLE_BY_COLUMN } from 'email-management-lib/refactor/actions/actionTypes';
import { BEGIN_COLUMN_CUSTOMIZATION, CLOSE_PREVIEW, RECEIVE_CAMPAIGN_ID, REQUEST_CAMPAIGN_ID, SAVE_COLUMN_CUSTOMIZATION, SELECT_EMAIL_ROW, SET_COLUMNS, TOGGLE_SELECT_ALL, TOGGLE_SELECT_EMAIL, BULK_EMAIL_SELECTION, CLEAR_BULK_EMAIL_SELECTION, UNSELECT_EMAILS } from 'email-management-lib/refactor/actions/actionTypes';
import { fetchEmails } from 'email-management-lib/refactor/actions/manage/general';
import { getCampaignIds } from 'email-management-lib/refactor/selectors/manage/table';
import { updatePathParams } from 'email-management-lib/refactor/actions/params';
// @ts-expect-error not migrated to TS yet
import { selectTableEmails } from 'email-management-lib/refactor/selectors/manage/tableEmails';
import { getEmailDetails } from 'EmailData/api/emails';
import { fetchLists } from 'EmailData/actions/contacts';
export function _sortTableByColumn(sortColumn) {
  trackDashboardInteraction({
    sorting: sortColumn
  });
  return {
    type: SORT_TABLE_BY_COLUMN,
    payload: {
      sortColumn
    }
  };
}
export function _setTableOffset(offset) {
  return {
    type: SET_TABLE_OFFSET,
    payload: {
      offset
    }
  };
}
export function _selectFolderRow(folderId) {
  return {
    type: SELECT_FOLDER_ROW,
    payload: {
      folderId
    }
  };
}
export function setTableOffset(offset) {
  return dispatch => {
    dispatch(_setTableOffset(offset));
    dispatch(fetchEmails());
  };
}
export function sortTableByColumn(sortColumn) {
  return dispatch => {
    dispatch(_sortTableByColumn(sortColumn));
    dispatch(fetchEmails());
  };
}
export function toggleSelectEmail(email) {
  return {
    type: TOGGLE_SELECT_EMAIL,
    payload: {
      email
    }
  };
}
export function toggleSelectAll(selected) {
  return (dispatch, getState) => {
    const state = getState();
    const allEmails = selectTableEmails(state);
    dispatch({
      type: TOGGLE_SELECT_ALL,
      payload: {
        selected,
        allEmails
      }
    });
  };
}
export function triggerBulkEmailSelection() {
  return {
    type: BULK_EMAIL_SELECTION
  };
}
export function clearSelectionAndBulkFlag(unselectEmails = true) {
  return dispatch => {
    dispatch({
      type: CLEAR_BULK_EMAIL_SELECTION
    });
    if (unselectEmails) {
      dispatch({
        type: UNSELECT_EMAILS
      });
    }
  };
}
export function selectEmailRow(contentId) {
  return (dispatch, getState) => {
    dispatch({
      type: SELECT_EMAIL_ROW,
      payload: {
        contentId
      }
    });
    if (!getCampaignIds(getState()).has(String(contentId))) {
      dispatch(fetchCampaignId(contentId));
    }
  };
}
export function selectFolderRow(folderId = '') {
  return dispatch => {
    dispatch(_selectFolderRow(folderId));
    const targetPath = `/manage/folder/${folderId}`;
    dispatch(updatePathParams({
      folderId
    }, fetchEmails, targetPath));
  };
}
export function closePreview() {
  return {
    type: CLOSE_PREVIEW
  };
}
export function beginColumnCustomization() {
  trackDashboardInteraction({
    otherActions: 'clicked-switch-columns'
  });
  return {
    type: BEGIN_COLUMN_CUSTOMIZATION
  };
}
export function setColumns(columns) {
  return {
    type: SET_COLUMNS,
    payload: {
      columns
    }
  };
}
export function saveColumnCustomization() {
  trackDashboardInteraction({
    otherActions: 'saved-switch-columns'
  });
  return {
    type: SAVE_COLUMN_CUSTOMIZATION
  };
}
export function fetchCampaignId(contentId) {
  return dispatch => {
    dispatch({
      type: REQUEST_CAMPAIGN_ID
    });
    getEmailDetails(contentId).then(emailDetails => {
      const campaignIds = emailDetails.allCampaignIds;
      dispatch({
        type: RECEIVE_CAMPAIGN_ID,
        payload: {
          [contentId]: campaignIds
        }
      });
      const allLists = [...emailDetails.primaryEmail.mailingListsIncluded, ...emailDetails.primaryEmail.mailingListsExcluded];
      dispatch(fetchLists(allLists));
    });
  };
}