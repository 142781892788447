import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
export function fetchAllPropertyValues(objectId, objectTypeId, httpClient) {
  return httpClient.get(`inbounddb-objects/v1/crm-objects/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}?includeAllValues=true`, {
    query: {
      portalId: PortalIdParser.get()
    }
  });
}
export const fetchDecryptedHighlySensitivePropertyValue = ({
  objectTypeId,
  objectId,
  propertyName,
  timestamp,
  httpClient
}) => {
  return httpClient.get(`inbounddb-objects-sensitive/v1/decrypted/${encodeURIComponent(objectTypeId)}/${encodeURIComponent(objectId)}/${encodeURIComponent(propertyName)}`, timestamp ? {
    query: {
      timestamp
    }
  } : {}).then(response => {
    if (!response) {
      return '';
    }
    return response.value || '';
  }).catch(err => {
    // When no value has been set the decrypt endpoint will return a 404
    // This might change: https://hubspot.slack.com/archives/C05V53DUAN6/p1720554309594509?thread_ts=1720552798.902799&cid=C05V53DUAN6
    if (err.status === 404) {
      return '';
    }
    Raven.captureException(err, {
      extra: {
        apiError: 'DECRYPT_HIGHLY_SENSITIVE_PROPERTY_VALUE_FAILED',
        objectId,
        objectTypeId,
        propertyName
      }
    });
    throw err;
  });
};