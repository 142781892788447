export const FILE_TYPE_ENHANCED_UX_GATE = 'CRM:Properties:FileTypeEnhancedUX';
export const FILE_MANAGER_NO_UPLOAD_LIMIT = 'file-manager-no-upload-limit';

// As of March 2024, BE allows upto 2GB for paid portals. CDP limits it to 50MB.
export const MAX_FILE_UPLOAD_SIZE_FREE_IN_MB = 20;
export const MAX_FILE_UPLOAD_SIZE_PAID_IN_MB = 50;
export const MAX_FILES_PER_PROPERTY = 10;

// error types
export const DISALLOWED_EXTENSION = 'DISALLOWED_EXTENSION';
export const MAX_UPLOAD_SIZE_EXCEEDED = 'MAX_UPLOAD_SIZE_EXCEEDED';
export const TIMEOUT = 'TIMEOUT';