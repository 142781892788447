import { parse } from 'hub-http/helpers/params';
import Raven from 'raven-js';
// Some of the functions below are the same as in the onboarding-tours library (https://git.hubteam.com/HubSpot/onboarding-tours)
// The reason we are not importing them is to avoid adding more dependencies to the coaching tips consuming app.
// This feature is meant to detect if there is an active onboarding tour and, if so, hide the coaching tips on the page.

import { useCallback, useEffect, useState } from 'react';
import { useIsGrowthSidePanelOpen } from './useIsGrowthSidePanelOpen';
import { isGrowthSidePanelAutoOpenEnabled } from '../utils/isGrowthSidePanelAutoOpenEnabled';
const ONBOARDING_NEXT_TOUR_SESSION_STORAGE_KEY = 'ONBOARDING_NEXT_TOUR';
const IN_APP_SIDE_PANEL_SESSION_STORAGE_KEY = 'Growth:InAppSidePanel:ShowSidepanel';
const MESSAGE_KEYS_ACTIONS = {
  ONBOARDING_TOURS: ['tourCompleted', 'close'],
  IN_APP_ONBOARDING_PANEL: ['closed']
};
export const getTourIdFromQueryParams = () => {
  const {
    tourId,
    onboardingTourId
  } = parse(document.location.search.slice(1));
  return tourId || onboardingTourId || null;
};
export const getValueFromSessionStorage = key => {
  let sessionStorageValue;
  try {
    sessionStorageValue = window.sessionStorage.getItem(key);
  } catch (error) {
    Raven.captureException(error);
  }
  return sessionStorageValue;
};
export const getNextTourIdFromStorage = () => {
  const nextTourValue = getValueFromSessionStorage(ONBOARDING_NEXT_TOUR_SESSION_STORAGE_KEY);
  if (nextTourValue) {
    const nextTourData = JSON.parse(nextTourValue);
    return nextTourData.tourId;
  }
  return null;
};
export const getSidePanelFromSessionStorage = () => {
  return getValueFromSessionStorage(IN_APP_SIDE_PANEL_SESSION_STORAGE_KEY);
};
export const subscribeToOnboardingComponentCompletion = callback => {
  const handler = event => {
    if (!event.data) {
      return;
    }
    const {
      key,
      action
    } = event.data;
    if (MESSAGE_KEYS_ACTIONS[key] && MESSAGE_KEYS_ACTIONS[key].includes(action)) {
      callback();
    }
  };
  window.addEventListener('message', handler, false);
  return () => window.removeEventListener('message', handler, false);
};
export const useIsOnboardingComponentActive = () => {
  const isGrowthSidePanelOpen = useIsGrowthSidePanelOpen();
  const hasActiveOnboardingComponent = useCallback(() => {
    return Boolean(document.querySelector('.onboarding-tour--active') || getTourIdFromQueryParams() || getNextTourIdFromStorage() || getSidePanelFromSessionStorage() || isGrowthSidePanelOpen);
  }, [isGrowthSidePanelOpen]);
  const [isOnboardingComponentActive, setIsOnboardingComponentActive] = useState(hasActiveOnboardingComponent() || isGrowthSidePanelAutoOpenEnabled());
  useEffect(() => {
    setIsOnboardingComponentActive(hasActiveOnboardingComponent());
    return subscribeToOnboardingComponentCompletion(() => {
      setIsOnboardingComponentActive(hasActiveOnboardingComponent());
    });
  }, [hasActiveOnboardingComponent]);
  return isOnboardingComponentActive;
};