'use es6';

import { Map as ImmutableMap } from 'immutable';
import { ON_GET_CANCELLED_SEND_REMEDIATION } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = ImmutableMap();
export default function cancelledSendRemediation(state = initialState, action) {
  switch (action.type) {
    case ON_GET_CANCELLED_SEND_REMEDIATION:
      if (action.error !== true) {
        return state.set('created', action.payload.created).set('error', action.payload.error).set('errorMessage', action.payload.errorMessage).set('id', action.payload.id);
      }
      return state;
    default:
      return state;
  }
}