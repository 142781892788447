'use es6';

import UpgradeProductToApiNameMap from '../constants/UpgradeProductToApiNameMap';
import ApiNameToUpgradeProductMap from '../constants/ApiNameToUpgradeProductMap';
import { buildTrialState } from '../core/utilities/trialUtils';
export const trialStateAdapter = trialState => {
  if (trialState.length === 0) return trialState;
  const transformedTrialState = {};
  trialState.forEach(hubTrial => {
    const {
      trials,
      createdAt,
      endsAt,
      removedAt
    } = hubTrial;
    trials.forEach(trial => {
      const {
        name,
        id
      } = trial;
      // for Marketing+ trials, the name of the trial is the ApiName, not the UpgradeProduct
      // to handle this, we will check if the name is an ApiName, if it is not we
      // will look it up in the UpgradeProductToApiNameMap.
      const label = name in ApiNameToUpgradeProductMap ? name : UpgradeProductToApiNameMap[name];
      transformedTrialState[label] = buildTrialState(createdAt, endsAt, removedAt, id);
    });
  });
  return transformedTrialState;
};