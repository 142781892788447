import { fetchAllPropertyValues } from './api';
import { InMemoryCache } from '../cache/InMemoryCache';
const toCacheKey = ({
  objectTypeId,
  objectId
}) => `${objectTypeId}/${objectId}`;
const fetcher = (key, promiseClient) => {
  const {
    objectTypeId,
    objectId
  } = key;
  return fetchAllPropertyValues(objectId, objectTypeId, promiseClient).then(res => Object.entries(res.properties).reduce((acc, [name, property]) => {
    acc[name] = property.value;
    return acc;
  }, {}));
};
export const makePropertyValuesCache = () => new InMemoryCache(fetcher, toCacheKey);