module.exports = {
  "campaignSelectInteraction": {
    "namespace": "campaigns-lib",
    "name": "Campaign select interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "auto select a campaign",
        "select a campaign",
        "load more campaigns",
        "onboarding tour link clicked"
      ],
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "isRecentlyUsed": {
        "type": "boolean",
        "isOptional": true
      },
      "containsSearchQuery": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      }
    },
    "version": "1"
  },
  "campaignsCreate": {
    "namespace": "campaigns",
    "name": "create",
    "class": "usage",
    "properties": {
      "screen": [
        "calendar",
        "campaign-budget",
        "campaign-editor",
        "campaign-select",
        "campaign",
        "tasks",
        "template-editor",
        "template-select"
      ],
      "action": [
        "campaign created",
        "campaign cloned"
      ],
      "colorSelected": {
        "type": "string",
        "isOptional": true
      },
      "hasStartDate": {
        "type": "boolean",
        "isOptional": true
      },
      "hasEndDate": {
        "type": "boolean",
        "isOptional": true
      },
      "hasOwner": {
        "type": "boolean",
        "isOptional": true
      },
      "hasDefinedGoal": {
        "type": "boolean",
        "isOptional": true
      },
      "hasDefinedAudience": {
        "type": "boolean",
        "isOptional": true
      },
      "hasDefinedBudget": {
        "type": "boolean",
        "isOptional": true
      },
      "hasDefinedNotes": {
        "type": "boolean",
        "isOptional": true
      },
      "hasDefinedStatus": {
        "type": "boolean",
        "isOptional": true
      },
      "assetType": {
        "type": "string",
        "isOptional": true
      },
      "businessUnitId": {
        "type": "number",
        "isOptional": true
      },
      "currencyCode": {
        "type": "string",
        "isOptional": true
      },
      "isDefault": {
        "type": "boolean",
        "isOptional": true
      },
      "fields": {
        "type": "array",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      }
    },
    "version": "1"
  }
};