'use es6';

import { BatchRequestClient } from './BatchRequestClient';
import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const BASE_ASSOCIATIONS_URL = 'associations/v1/associations-frontend/single-portal-queries';
const MAX_REQUEST_SIZE = 200;
export const makeRequestKey = ({
  associationCategory,
  associationTypeId,
  objectId
}) => `${associationCategory}.${associationTypeId}.${objectId}`;
export const makeRequestBody = requestOptionsByKey => Object.keys(requestOptionsByKey).map(requestKey => {
  const {
    associationCategory,
    associationTypeId,
    objectId
  } = requestOptionsByKey[requestKey];
  return {
    portalId: PortalIdParser.get(),
    fromObjectId: objectId,
    associationCategory,
    associationTypeId,
    limit: 100,
    offset: 0
  };
});
export const api = ({
  requestBody
}) => http.post(BASE_ASSOCIATIONS_URL, {
  data: requestBody
}).then(response => response.reduce((acc, {
  associationTypeId,
  category,
  fromObjectId,
  results
}) => {
  // We have to make the request key here using the result of the response
  // because we have to map the result from our API to the requestKeys in
  // the BatchRequestClient.
  const requestKey = makeRequestKey({
    associationCategory: category,
    associationTypeId,
    objectId: fromObjectId
  });
  acc[requestKey] = results;
  return acc;
}, {}));
export const AssociationsBatchRequestClient = new BatchRequestClient({
  api,
  makeRequestBody,
  makeRequestKey,
  options: {
    MAX_REQUEST_SIZE
  }
});
export const __resetAssociationsBatchCache = () => {
  AssociationsBatchRequestClient.clearCache();
};