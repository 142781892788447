/* eslint-disable @typescript-eslint/no-floating-promises */

import PortalIdParser from 'PortalIdParser';
import * as storage from 'EmailData/lib/storage';
import { writeToUserAttributesInManage } from 'EmailData/api/hubUserAttributes';
import { LOCAL_STORAGE_BASE_KEY, SETTINGS_DURATION_MILLIS } from 'email-management-lib/refactor/utils/constants';
const STATE_TO_PERSIST = {
  manageColumns: 'manage.table.visibleColumns',
  dashboardZeroStateComplete: 'dashboardZeroStateComplete',
  dismissedOutageCritsitBanner: 'manage.dismissedOutageCritsitBanner',
  dismissedToolsSidebarCarousel: 'manage.dismissedToolsSidebarCarousel',
  dismissedOnboardingTourEntry: 'manage.dismissedOnboardingTourEntry',
  dismissedOnboardingTourTime: 'manage.dismissedOnboardingTourTime'
};
let lastState;

/**
 * @typedef {Object} SettingsListenerProps
 * @prop {Function} getCurrentStore Current redux store (redux listeners don't provide the current state by default)
 * @prop {Function} getIsCachedOnly
 * @prop {Function} getUserId Current user id
 * @prop {Function} getUserSettings Current user settings
 * @prop {Function} onUpdateUserSettings Updates user settings when there's difference between "lastState" and "currentState"
 */

/**
 * User settings redux listener
 *
 * Syncs BE user settings with localStorage settings whenever keys in @STATE_TO_PERSIST have changed.
 * It awaits for a batch of state updates before being called = it's very likely we end up
 * updating multiple settings at once when suitable.
 * @param {SettingsListenerProps} props
 */
const userSettingsListener = ({
  getCurrentStore,
  getIsCachedOnly,
  getUserId,
  getUserSettings,
  onUpdateUserSettings
}) => {
  lastState = undefined;
  return () => {
    // Reject updates while on local cache only.
    if (getIsCachedOnly()) {
      // Portal attempted to make user attribute changes before server sync
      return;
    }
    const currentStore = getCurrentStore();
    const currentState = currentStore.getState();

    // @lastState is yet not defined, no reason for checking state updates
    if (!lastState) {
      lastState = currentState;
      return;
    }
    let hasStateUpdates = false;
    Object.keys(STATE_TO_PERSIST).forEach(stateKey => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const statePath = STATE_TO_PERSIST[stateKey].split('.');
      const newValue = currentState.getIn(statePath);
      if (lastState.getIn(statePath) !== newValue) {
        onUpdateUserSettings(stateKey, newValue);
        hasStateUpdates = true;
      }
    });
    lastState = currentState;
    if (!hasStateUpdates) {
      return;
    }

    // Update user settings (storage and API)
    const settingsAsJS = getUserSettings();
    storage.set(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    PortalIdParser.get(), LOCAL_STORAGE_BASE_KEY, getUserId(), settingsAsJS, SETTINGS_DURATION_MILLIS);
    writeToUserAttributesInManage(settingsAsJS, true);
  };
};
export default userSettingsListener;