import { useQuery, registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
const EMAIL_TEAMS = 'emailTeams';
const GET_TEAMS = registerQuery({
  fieldName: EMAIL_TEAMS,
  args: ['canViewAllAssets'],
  fetcher({
    canViewAllAssets
  }) {
    if (canViewAllAssets) {
      return http.get(`app-users/v1/teams/hierarchy`);
    }
    return http.get(`app-users/v1/teams/user/all`, {
      query: {
        includeChildTeamMembers: true,
        includeHierarchy: true
      }
    });
  }
});
export default function useFetchTeams(canViewAllAssets) {
  const {
    data,
    error,
    loading
  } = useQuery(GET_TEAMS, {
    variables: {
      canViewAllAssets
    }
  });
  return {
    teams: data && data[EMAIL_TEAMS] && !loading && !error ? data[EMAIL_TEAMS] : [],
    fetchTeamsLoading: loading
  };
}