import { combineReducers } from 'redux';
import combineImmutableReducers from 'EmailData/reducers/helpers/combineImmutableReducers';
// @ts-expect-error not migrated to TS yet
import cancelledSendRemediation from './cancelledSendRemediation';
// @ts-expect-error not migrated to TS yet
import comparisonToolReturnLocation from './comparisonToolReturnLocation';
// @ts-expect-error not migrated to TS yet
import dashboardZeroStateComplete from './dashboardZeroStateComplete';
import details from '../details';
// @ts-expect-error not migrated to TS yet
import entities from './entities';
// @ts-expect-error not migrated to TS yet
import integrationSettings from './integrationSettings';
// @ts-expect-error not migrated to TS yet
import manage from 'email-management-lib/refactor/reducers/manage';
// @ts-expect-error not migrated to TS yet
import pathParams from 'EmailData/reducers/pathParams';
// @ts-expect-error not migrated to TS yet
import portalStatus from './portalStatus';
// @ts-expect-error not migrated to TS yet
import queryParams from 'EmailData/reducers/queryParams';
// @ts-expect-error not migrated to TS yet
import userAttributes from './userAttributes';
// @ts-expect-error not migrated to TS yet
import users from './users';
import experiments from './experiments';
import inventory from './inventory';
// @ts-expect-error not migrated to TS yet
import { healthThresholds } from './healthThresholds';
import emailFrameworkOnboarding from './emailFrameworkOnboarding';

// shared reducers
// @ts-expect-error not migrated to TS yet
import blogEmailsReducer from 'EmailData/reducers/blogEmails';
import contentPartitionTeams from 'ContentData/reducers/Teams';
import contentPartitionUsers from 'ContentData/reducers/Users';
import emailAddressOwnership from 'EmailData/reducers/emailAddressOwnership';
// @ts-expect-error not migrated to TS yet
import portalSendCredits from 'EmailData/reducers/portalSendCredits';
import portalTrialsReducer from 'EmailData/reducers/portalTrials';
import recipientsReducer from 'EmailData/reducers/recipientsReducer';
// @ts-expect-error not migrated to TS yet
import rssFeedUrlValidationReducer from 'EmailData/reducers/rssFeedUrlValidation';
// @ts-expect-error not migrated to TS yet
import smartInsightsReducer from 'EmailData/reducers/smartInsights';
import testSendData from 'EmailData/reducers/testSendData';
// @ts-expect-error not migrated to TS yet
import { feedbackClient } from 'EmailData/feedback/reducers';
// @ts-expect-error not migrated to TS yet
import portalSettings from 'EmailData/portalSettings/reducers';
// @ts-expect-error not migrated to TS yet
import portalMigration from 'EmailData/reducers/portalMigration';
import teamHubSettings from 'EmailData/teamHubSettings/reducers';
import healthHistory from 'email-health-ui-lib/metrics/reducers/healthHistory';
import selectedHealthScoreType from 'email-health-ui-lib/healthScoreTypeSelection/reducers/selectedHealthScoreType';
// @ts-expect-error not migrated to TS yet
import rollupLimit from 'EmailData/reducers/rollupLimit';
import approvalsConfig from 'EmailData/reducers/approvals';
const rootReducer = combineImmutableReducers({
  approvalsConfig,
  manage,
  details,
  emailFrameworkOnboarding,
  entities,
  experiments,
  inventory,
  feedbackClient,
  portalStatus,
  portalSettings,
  cancelledSendRemediation,
  pathParams,
  queryParams,
  users,
  // shared reducers
  blogEmails: blogEmailsReducer,
  emailAddressOwnership,
  portalSendCredits,
  portalTrialStatus: portalTrialsReducer,
  recipients: recipientsReducer,
  rssFeedUrlValidation: rssFeedUrlValidationReducer,
  smartInsights: smartInsightsReducer,
  testSendData,
  // Combine without immutables, as they come stored as objects
  resources: combineReducers({
    teams: contentPartitionTeams,
    users: contentPartitionUsers
  }),
  dashboardZeroStateComplete,
  integrationSettings,
  userAttributes,
  comparisonToolReturnLocation,
  healthThresholds,
  portalMigration,
  metrics: combineReducers({
    healthHistory
  }),
  healthScoreTypeSelection: combineReducers({
    selectedHealthScoreType
  }),
  teamHubSettings,
  rollupLimit
});
export default rootReducer;