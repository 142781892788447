import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { useCallback } from 'react';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { useHttpClient } from '../../client/HttpClientContext';

/**
 * Patches up a property definition for internal use in the
 * property input and formatter components. Some upstream callers fail to
 * pass in complete property definitions, so this hook is used to fill in the
 * gaps. Ideally we would fix the upstream callers, but at the moment it is too
 * widespread and we need to make things work.
 *
 * NOTE: this is a non-blocking solution that will continue using the given
 * definition until we get a response from the server to patch it.
 */
export const usePatchedPropertyDefinition = ({
  property,
  objectTypeId
}) => {
  var _result$data;
  const httpClient = useHttpClient();
  const getPatchedProperty = useCallback(async () => {
    let _property = property;
    if (!property.dataSensitivity) {
      const definition = await getFrameworkDataSchemasClient({
        httpClient
      }).then(client => client.properties.getProperty({
        frameworkTypeIdentifier: objectTypeId,
        propertyName: property.name,
        query: {
          showHighlySensitiveProperties: true
        }
      }));
      _property = Object.assign({}, _property, {
        dataSensitivity: definition.property.dataSensitivity
      });
    }
    return _property;
  }, [httpClient, objectTypeId, property]);
  const result = useAsyncFunction(getPatchedProperty);
  return (_result$data = result.data) !== null && _result$data !== void 0 ? _result$data : property;
};