import { FETCH_PORTAL_MIGRATION_START, FETCH_PORTAL_MIGRATION_SUCCESS, FETCH_PORTAL_MIGRATION_FAIL } from './actionTypes';
import portalMigrationStatus from '../api/portalMigration';
import Raven from 'raven-js';
export const fetchPortalMigrationStatus = () => {
  return dispatch => {
    dispatch({
      type: FETCH_PORTAL_MIGRATION_START
    });
    portalMigrationStatus().then(response => {
      dispatch({
        type: FETCH_PORTAL_MIGRATION_SUCCESS,
        payload: response
      });
    }).catch(err => {
      dispatch({
        type: FETCH_PORTAL_MIGRATION_FAIL
      });
      if (!err.status || err.status !== 404) {
        Raven.captureException(err);
      }
    });
  };
};