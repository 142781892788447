'use es6';

import { Record } from 'immutable';
import { generateLinkRegexString } from 'email-management-lib/refactor/utils/details/ctasUtil';
const defaults = {
  link: '',
  linkId: -1,
  linkRegexStr: '',
  isHistorical: false
};
class ClickMapData extends Record(defaults) {
  static from(json) {
    return new ClickMapData(Object.assign({}, json, {
      linkRegexStr: generateLinkRegexString(json.link)
    }));
  }
}
export default ClickMapData;