import { CMS_HUB_ENTERPRISE, CMS_HUB_PROFESSIONAL, MARKETING_HUB_ENTERPRISE, MARKETING_HUB_PROFESSIONAL, OPERATIONS_HUB_ENTERPRISE, OPERATIONS_HUB_PROFESSIONAL } from 'self-service-api/constants/ApiNames';
const VALID_API_NAMES_FOR_CORE_SEAT = [MARKETING_HUB_PROFESSIONAL, MARKETING_HUB_ENTERPRISE, OPERATIONS_HUB_PROFESSIONAL, OPERATIONS_HUB_ENTERPRISE, CMS_HUB_PROFESSIONAL, CMS_HUB_ENTERPRISE];
export const getOwnedHubs = products => {
  const init = [];
  const ownedHubs = products.reduce((accumulator, currentValue) => {
    const {
      productLine,
      productApiName
    } = currentValue;
    if (productLine && VALID_API_NAMES_FOR_CORE_SEAT.includes(productApiName)) {
      return [...accumulator, productApiName];
    }
    return accumulator;
  }, init);
  return ownedHubs;
};