import PortalIdParser from 'PortalIdParser';
const PROPERTY_VALIDATION_ROOT_URI = 'property-validation/v1/property-validation';
export const validatePropertyValues = (requests, httpClient, objectTypeId, objectId) => {
  return httpClient.post(`${PROPERTY_VALIDATION_ROOT_URI}/${encodeURIComponent(objectTypeId)}/extended`, {
    data: requests,
    query: {
      customValidation: true,
      objectId,
      portalId: PortalIdParser.get()
    }
  });
};