import { FETCH_PORTAL_TRIALS_SUCCESS } from 'EmailData/actions/actionTypes';
const initialState = {
  portalTrials: []
};
const portalTrialsReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_PORTAL_TRIALS_SUCCESS:
      return Object.assign({}, state, {
        portalTrials: payload
      });
    default:
      return state;
  }
};
export default portalTrialsReducer;