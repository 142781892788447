'use es6';

import { List, Map as ImmutableMap, Record } from 'immutable';
import Email from 'EmailData/models/Email';
import EmailCampaignSend from 'EmailData/models/details/children/EmailCampaignSend';
import Template from 'EmailData/models/Template';
const detailDefaults = {
  appId: null,
  crmObjectId: null,
  primaryEmailId: null,
  primaryEmail: Email.from({}),
  primaryTemplateInfo: Template.from({}),
  campaignErrorResults: ImmutableMap({}),
  allAppIds: List([]),
  allCampaignIds: List([]),
  appCampaignIdPairs: List([]),
  emailCampaignSends: List([]),
  trackingConfiguration: {
    htmlClickTrackingEnabled: true,
    openTrackingEnabled: true,
    plainTextClickTrackingEnabled: true
  }
};
export default (defaultValues => class extends Record(Object.assign({}, detailDefaults, defaultValues)) {
  static from(json) {
    const response = Object.assign({}, json);
    if (typeof response.allCampaignIds !== 'undefined') {
      response.allCampaignIds = List(response.allCampaignIds);
    }
    if (typeof response.primaryEmail !== 'undefined') {
      response.primaryEmail = Email.from(Object.assign({}, response.primaryEmail));
    }
    if (typeof response.emailCampaignSends !== 'undefined') {
      response.emailCampaignSends = List(response.emailCampaignSends.map(send => EmailCampaignSend.from(send)));
    }
    if (typeof response.campaignErrorResults !== 'undefined') {
      response.campaignErrorResults = ImmutableMap(response.campaignErrorResults);
    }
    if (typeof response.primaryTemplateInfo !== 'undefined') {
      response.primaryTemplateInfo = Template.from(Object.assign({}, response.primaryTemplateInfo));
    }
    return response;
  }
  getEmail() {
    return this.primaryEmail;
  }
  getTemplateInfo() {
    return this.primaryTemplateInfo;
  }
  getAllCampaignSends() {
    return this.emailCampaignSends;
  }
});