'use es6';

export const FEEDBACK_FEELINGS = Object.freeze({
  NEGATIVE: 0,
  NEUTRAL: 1,
  POSITIVE: 2
});
export const FEEDBACK_MESSAGE_TYPES = {
  CARD: 'card',
  EDUCATION: 'education',
  RECOMMENDATION: 'recommendation'
};
export const SHARED_FEEDBACK_IDS = {
  PORTAL_RECOVERY: 65
};