import { useCallback, useMemo } from 'react';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import useFetchUserSettings from 'framework-listing-lib/internal/userSettings/hooks/useFetchUserSettings';
import useSaveUserSettings from 'framework-listing-lib/internal/userSettings/hooks/useSaveUserSettings';
import { safeParseJSON } from 'framework-listing-lib/utils/json';
export default function useTableColumnConfiguration() {
  const saveUserSettings = useSaveUserSettings();
  const objectType = useObjectTypeId();
  const {
    data
  } = useFetchUserSettings(`CustomColumnsConfig-${objectType}`, {
    defaultValue: '{}',
    quickFetchKey: `fetch-crm-objectType-custom-columns-${objectType}`
  });
  const columnConfiguration = useMemo(() => data ? safeParseJSON(data.value, undefined) : undefined, [data]);
  const saveColumnConfiguration = useCallback(newConfig => {
    return saveUserSettings({
      key: `CustomColumnsConfig-${objectType}`,
      value: JSON.stringify(newConfig)
    }).catch(error => {
      throw error;
    });
  }, [objectType, saveUserSettings]);
  return useMemo(() => ({
    saveColumnConfiguration,
    columnConfiguration
  }), [columnConfiguration, saveColumnConfiguration]);
}