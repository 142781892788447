import PortalIdParser from 'PortalIdParser';
import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { getFullUrl } from 'hubspot-url-utils';
import { getCurrentPathname, parseQueryParams } from 'EmailData/utils/url';
import { matchPath } from 'react-router-dom';
export const HTTP = 'http://';
export const HTTPS = 'https://';
export const getUrlAndProtocol = fullUrl => {
  const protocolRegex = new RegExp(/^(http|https):\/\//);
  if (!protocolRegex.test(fullUrl)) {
    return {
      url: fullUrl
    };
  }
  const protocolMatch = fullUrl.match(protocolRegex);
  const protocol = protocolMatch !== null ? protocolMatch[0] : undefined;
  const url = fullUrl.replace(protocolRegex, '');
  return {
    protocol,
    url: url || ''
  };
};
export function navigateTo(href, external) {
  const subdomain = window.location.hostname.split('.')[0].split('-')[0];
  const url = `${getFullUrl(subdomain)}${href}`;
  if (external) {
    window.open(url, '_blank', 'noopener');
  } else {
    window.location.href = url;
  }
}
export const getUserGuideUrl = skipFrom => `/user-guide/${PortalIdParser.get()}?via=skip-${skipFrom}`;
export const getContactsPageUrlWithTour = () => `/contacts/${PortalIdParser.get()}/objects/0-1/views/all/list?via=mailchimp-importer&onboardingTourId=data-sync-contacts`;
export const getContactsPageUrl = () => `/contacts/${PortalIdParser.get()}/objects/0-1/views/all/list`;
export const getCrmListingsUrlForObject = (objectType, filters) => {
  const objectId = ObjectTypesToIds[objectType];
  const baseUrl = `/contacts/${PortalIdParser.get()}/objects/${objectId}/views/all`;
  if (!filters || filters.length === 0) {
    return baseUrl;
  }
  return `${baseUrl}?filters=${encodeURIComponent(JSON.stringify(filters))}`;
};
export const getIsViaDataManagement = () => {
  const query = parseQueryParams(window.location.search);
  return query.via === 'data-management';
};
export const getIsViaGrowthPlan = () => {
  const query = parseQueryParams(window.location.search);
  return query.via === 'growth-plan';
};
export const getIsViaUserGuide = () => {
  const query = parseQueryParams(window.location.search);
  return query.via === 'user-guide-data-sync-modal';
};
export const getIsViaCrmIndex = () => {
  const query = parseQueryParams(window.location.search);
  return query.via === 'crm-index';
};
export function redirectToTemplate(templateId) {
  window.location.href = `/email/${PortalIdParser.get()}/edit-template/${templateId}/content?isFromEmailListing=true`;
}
export function hasParametersChanged(newLocationSearch, oldLocationSearch, newPathParams, oldPathParams) {
  return newLocationSearch !== oldLocationSearch || JSON.stringify(newPathParams) !== JSON.stringify(oldPathParams);
}
export function shouldNavigateRefresh(historyAction, newLocationSearch, oldLocationSearch, newPathParams, oldPathParams) {
  return historyAction === 'POP' && hasParametersChanged(newLocationSearch, oldLocationSearch, newPathParams, oldPathParams);
}
export function isPathActive(path) {
  return matchPath(path, {
    path: getCurrentPathname()
  });
}