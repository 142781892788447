'use es6';

import styled, { css } from 'styled-components';
import UITruncateString from '../../text/UITruncateString';
import { isFirefox } from '../../utils/BrowserTest';

// In Firefox, the truncated text will overlap the arrows unless the width is reduced.
const reducedWidthMixin = css(["width:calc(100% - 14px);"]);
const SortTH_TruncateString = styled(UITruncateString).withConfig({
  displayName: "SortTH_TruncateString",
  componentId: "sc-1nsh1cs-0"
})(["&&{", ";}"], isFirefox() ? reducedWidthMixin : null);
SortTH_TruncateString.propTypes = UITruncateString.propTypes;
SortTH_TruncateString.defaultProps = UITruncateString.defaultProps;
export default SortTH_TruncateString;