import { List, Map as ImmutableMap, fromJS } from 'immutable';

// better types for immutable map and list.
// largely copied from https://hubspot.slack.com/archives/C054KSE5LBA/p1681878202098509
// with some modifications to handle nested objects, arrays and toJS better.
// A utility function to build an immutable Map of a given structure, ensuring that initial keys and values conform to the given type
export const buildTypedMap = data => ImmutableMap(data);

// immutablejs 'fromJS' but typesafe :D
export const typedFromJS = data => {
  return fromJS(data);
};

/** Gets the type contained within a TypedMap. */

export function isImmutableMap(maybeMap) {
  return maybeMap !== undefined && ImmutableMap.isMap(maybeMap);
}
export const isAsList = value => List.isList(value);