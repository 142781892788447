import { inboundDbPropertyValidator } from 'customer-data-properties/validation/inboundDbPropertyValidator/validator';
import { domainPropertyValidator } from './domainPropertyValidator/validator';
import { emailPropertyValidator } from './emailPropertyValidator/validator';
import { billingPeriodFrequencyPropertyValidator } from './billingPeriodFrequencyPropertyValidator/validator';
import { isContactEmail, isDomain, isName, isMonthPeriod, isBillingFrequency } from 'customer-data-objects/property/PropertyIdentifier';
import { denormalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { namePropertyValidator } from './namePropertyValidator/validator';
import { isRevenueEmail } from '../v2/utils/identifiers';
const DefaultPropertyValidators = [inboundDbPropertyValidator];

// Right now this function just returns the default property validators but
// the point of this function is to provide an easy place to add additional
// validators in the future if we need them.
//
// For example if we added a email property type one day and wanted to add a
// custom validator specifically for it (that only ran for email properties) we
// could add that validator here along with the logic to conditionally use it
// only for email properties. We expect most validation to happen via the
// property validation backend (one of the default property validators) but this
// function acts as an escape hatch if we ever have to add custom validators
// so we don't have to make a ton of changes to the rest of the validation logic
// if that ever occurs.
//
// Note that this does not include app specific customization, validators added
// here will apply across all uses of the validation function so they should
// only be added here if that is the intent.
export const getPropertyValidators = propertyValidationRequest => {
  if (isContactEmail(propertyValidationRequest.property, denormalizeTypeId(propertyValidationRequest.objectTypeId))) {
    // We always want to return the default validator for emails because it was
    // used before we added custom validation rules
    return [emailPropertyValidator, ...DefaultPropertyValidators];
  }
  if (isRevenueEmail(propertyValidationRequest.property, denormalizeTypeId(propertyValidationRequest.objectTypeId))) {
    // We always want to return the default validator for emails because it was
    // used before we added custom validation rules
    return [emailPropertyValidator, ...DefaultPropertyValidators];
  }
  if (isDomain(propertyValidationRequest.property, denormalizeTypeId(propertyValidationRequest.objectTypeId))) {
    return [domainPropertyValidator, ...DefaultPropertyValidators];
  }
  if (isName(propertyValidationRequest.property, denormalizeTypeId(propertyValidationRequest.objectTypeId))) {
    return [namePropertyValidator, ...DefaultPropertyValidators];
  }
  if (isMonthPeriod(propertyValidationRequest.property, propertyValidationRequest.objectTypeId) || isBillingFrequency(propertyValidationRequest.property, propertyValidationRequest.objectTypeId)) {
    return [billingPeriodFrequencyPropertyValidator, ...DefaultPropertyValidators];
  }
  return DefaultPropertyValidators;
};