'use es6';

import { connect } from 'react-redux';
import { SUSPENSION_REASON_TYPES } from 'EmailData/constants/portalSuspension';
import PortalHealthStatusAlert from 'EmailComponents/status/PortalHealthStatusAlert';
import { getPortalRestrictions, portalHealthStatusSelector, showPortalProbationWarningSelector } from 'EmailData/portalStatus/selectors';
import { trackRemediationInteraction } from 'EmailComponents/utils/tracking';
import { updatePortalDismissStatus } from 'EmailData/portalStatus/actions';
const mapStateToProps = state => {
  const healthStatus = portalHealthStatusSelector(state);
  const reason = healthStatus.getReason();
  return {
    feedbackInput: reason !== SUSPENSION_REASON_TYPES.BLACKLISTING ? {
      reason,
      state: healthStatus.getCurrentState()
    } : null,
    onTrackUsage: trackRemediationInteraction,
    portalRestrictions: getPortalRestrictions(state),
    portalHealthStatus: healthStatus,
    shouldShowProbationWarning: showPortalProbationWarningSelector(state)
  };
};
const mapDispatchToProps = {
  onClose: () => updatePortalDismissStatus(true)
};
export default connect(mapStateToProps, mapDispatchToProps)(PortalHealthStatusAlert);