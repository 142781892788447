import http from 'hub-http/clients/apiClient';
import { CRM_SEARCH_REQUEST_HAS_ARCHIVED_OPTIONS, CRM_SEARCH_REQUEST_HAS_EMAILS_OPTIONS, PROPERTIES } from 'email-management-lib/refactor/constants/manage/frameworkTable';
import zip from 'hs-lodash/zip';
import { Map as ImmutableMap } from 'immutable';
import CRMEmail from 'email-management-lib/refactor/models/CRMEmail';
import { getEarlyEmailListingArchivedKey, getEarlyEmailListingHasEmailsKey } from 'email-management-lib/refactor/earlyRequesters/requestUtils';
import { sanitizeEmailQuery } from 'EmailData/api/emails';
import { getEarlyRequest, readEarlyOrFetch } from 'EmailData/api/earlyRequests';
import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
import { MANAGE_URL_REG } from 'email-management-lib/refactor/constants/manage/shared';

// @ts-expect-error not typed
import Email from 'EmailData/models/Email';
const DEFAULT_OPTIONS = {
  timeout: 30000
};
const DEFAULT_QUERY = {
  archived: false,
  limit: 15,
  offset: 0,
  order: '-updated'
};
const URI = 'cosemail/v1/emails/listing';
export function exportEmailsInFrameworkRequest(settings) {
  const url = 'cosemail/v1/export/dashboard-crm';
  const options = {
    data: settings
  };
  return http.post(url, options);
}
function emptyResult() {
  return Promise.resolve({
    objects: [],
    offset: 0,
    total: 0,
    totalCount: 0
  });
}
export function getEmails(query = {}, opts = {}) {
  const sanitizedQuery = sanitizeEmailQuery(query);
  if (Object.getOwnPropertyNames(sanitizedQuery).length === 0) {
    return emptyResult();
  }
  const mergedQuery = Object.assign({}, DEFAULT_QUERY, sanitizedQuery);
  const options = Object.assign({}, DEFAULT_OPTIONS, opts);
  return http.get(URI, Object.assign({}, options, {
    query: mergedQuery
  })).then(data => {
    const emails = data.objects;
    const ids = (emails || []).map(el => el.id);
    return Object.assign({}, data, {
      ids,
      emails: ImmutableMap(zip(ids, (emails || []).map(email => Email.from(email))))
    });
  });
}
export const tagMinimalAndParse = ({
  tagValue
}) => data => {
  const emails = data.results;
  const ids = [];
  if (!emails) {
    Raven.captureException(new Error('emails is undefined'), {
      extra: {
        data
      }
    });
  }
  (emails || []).forEach(email => {
    email.isMinimalEmail = tagValue;
    ids.push(parseInt(email.properties[PROPERTIES.MARKETING_EMAIL_ID].value, 10));
  });
  return Object.assign({}, data, {
    ids,
    emails: ImmutableMap(zip(ids, (emails || []).map(email => CRMEmail.from(email))))
  });
};
export function fetchHasArchived() {
  const earlyArchivedRequest = getEarlyRequest(getEarlyEmailListingArchivedKey());
  if (earlyArchivedRequest && MANAGE_URL_REG.test(window.location.pathname)) {
    return readEarlyOrFetch(getEarlyEmailListingArchivedKey());
  }
  return getEmails({
    state: 'archived',
    limit: 1
  });
}
export function fetchHasEmails() {
  const earlyHasEmailsRequest = getEarlyRequest(getEarlyEmailListingHasEmailsKey());
  if (earlyHasEmailsRequest && MANAGE_URL_REG.test(window.location.pathname)) {
    return readEarlyOrFetch(getEarlyEmailListingHasEmailsKey());
  }
  return getEmails({
    state: 'all',
    limit: 1
  });
}
export function exportUnsubsAndBouncesRequest(query) {
  const url = 'email/v1/export';
  const options = {
    data: query
  };
  return http.post(url, options);
}
export function fetchHasArchivedByCrmSearch() {
  return http.post('crm-search/search/ids', {
    data: CRM_SEARCH_REQUEST_HAS_ARCHIVED_OPTIONS
  });
}
export function fetchHasEmailsByCrmSearch() {
  return http.post('crm-search/search/ids', {
    data: CRM_SEARCH_REQUEST_HAS_EMAILS_OPTIONS
  });
}
export function updateEmailDetails(emailId, updatedFields, ignoreTimestamp) {
  const urlSuffix = ignoreTimestamp ? '/update-without-timestamping' : '';
  const url = `cosemail/v1/emails/${emailId}${urlSuffix}`;
  const options = {
    query: {
      portalId: PortalIdParser.get()
    },
    data: updatedFields
  };
  return http.put(url, options);
}