import Raven from 'raven-js';
import { useCallback, useEffect } from 'react';
import devLogger from 'react-utils/devLogger';
import { Metrics } from '../../metrics/Metrics';
function useGetAdditionalPropertyValueProxy({
  getDraftValue,
  getSavedValue,
  getAdditionalPropertyValue,
  objectTypeId,
  propertyName,
  componentName
}) {
  // Proxy `getAdditionalPropertyValue` by optionally using `getDraftValue` and `getSavedValue`
  // This is part of an effort to deprecate `getAdditionalPropertyValue` in favor of two separate props.
  // More details here: https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1602
  const getAdditionalPropertyValueProxy = useCallback((...args) => {
    if (getDraftValue && getSavedValue) {
      const propertyValue = getDraftValue(...args);
      return propertyValue === undefined ? getSavedValue(...args) : propertyValue;
    }
    if (getAdditionalPropertyValue) {
      return getAdditionalPropertyValue(...args);
    }
    throw new Error('Caller must provide one of getAdditionalPropertyValue or getSavedValue/getDraftValue');
  }, [getAdditionalPropertyValue, getDraftValue, getSavedValue]);
  useEffect(() => {
    if (getDraftValue === undefined && getSavedValue === undefined && getAdditionalPropertyValue === undefined) {
      devLogger.warn({
        message: `${componentName}-get-additional-value-functions-canary: did not receive any of getDraftValue, getSavedValue, or getAdditionalPropertyValue`,
        key: `${componentName}-get-additional-value-functions-canary`
      });
      Metrics.counter(`${componentName}-get-additional-value-functions-canary`).increment();
      Raven.captureException(new Error(`${componentName}-get-additional-value-functions-canary`), {
        extra: {
          objectTypeId,
          propertyName
        }
      });
    }
  }, [componentName, getAdditionalPropertyValue, getDraftValue, getSavedValue, objectTypeId, propertyName]);
  return getAdditionalPropertyValueProxy;
}
export default useGetAdditionalPropertyValueProxy;