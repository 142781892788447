import { indexBy } from '../utils/indexBy';
const indexByPipelineId = indexBy(({
  pipelineId: id
}) => id);
export class PipelinesCache {
  constructor() {
    this.cache = {};
  }
  get({
    objectTypeId,
    httpClient
  }) {
    if (Object.prototype.hasOwnProperty.call(this.cache, objectTypeId)) {
      return this.cache[objectTypeId];
    }
    const promise = httpClient.get(`/pipelines/v2/pipelines/${encodeURIComponent(objectTypeId)}?includeApprovalStageStatus=true`).then(pipelines => indexByPipelineId(pipelines));
    this.cache[objectTypeId] = promise;
    return promise;
  }
  clear() {
    this.cache = {};
  }
}
export const SharedPipelinesCache = new PipelinesCache();