export class InMemoryCache {
  constructor(fetch, serializeKey) {
    this.cache = new Map();
    this.cache = new Map();
    this.fetch = fetch;
    this.serializeKey = serializeKey;
  }
  get(key, promiseClient, refetch = false) {
    const cacheKey = this.serializeKey(key);
    if (!this.cache.has(cacheKey) || refetch) {
      this.cache.set(cacheKey, this.fetch(key, promiseClient));
    }
    return this.cache.get(cacheKey);
  }
  delete(key) {
    const cacheKey = this.serializeKey(key);
    return this.cache.delete(cacheKey);
  }
  clear() {
    this.cache.clear();
  }
}