import styled, { css } from 'styled-components';
import UISortTH from 'UIComponents/table/UISortTH';
import UIClickable from 'UIComponents/button/UIClickable';
import { CALYPSO, GREAT_WHITE } from 'HubStyleTokens/colors';
import { TABLE_DEFAULT_FONT_SIZE } from 'HubStyleTokens/sizes';
import UIBreakString from 'UIComponents/text/UIBreakString';
export const tableCellFocusStyles = css([":focus{outline:-2px solid ", ";box-shadow:inset 0 0 0 1px ", ";[data-test-id='resize-affordance']{border-right:none;}}"], CALYPSO, CALYPSO);

// Adapted from `customer-data-table/static/js/tableComponents/Tbody.js`
export const TableBody = styled.tbody.attrs({
  'data-test-id': 'crm-table-tbody'
}).withConfig({
  displayName: "StyledComponents__TableBody",
  componentId: "ny0xjc-0"
})(["height:100%;width:100%;border-bottom:1px solid ", ";&:last-of-type{border-bottom:none;}"], GREAT_WHITE);
const tableCellCrmUI = css(["border-color:", " !important;border-width:0 1px 0 0 !important;"], GREAT_WHITE);

// Adapted from `customer-data-table/static/js/tableComponents/TableActionsTr`
export const StyledTr = styled.tr.withConfig({
  displayName: "StyledComponents__StyledTr",
  componentId: "ny0xjc-1"
})(["display:flex;align-items:center;width:100%;"]);

// Adapted from `customer-data-table/static/js/tableComponents/TableActionsTr`
export const StyledTH = styled.th.withConfig({
  displayName: "StyledComponents__StyledTH",
  componentId: "ny0xjc-2"
})(["align-items:center;border-bottom:", " 1px solid;display:flex;height:auto;width:100%;&&{font-size:", ";padding-right:0;text-transform:none;}"], GREAT_WHITE, TABLE_DEFAULT_FONT_SIZE);
// Adapted from `customer-data-table/static/js/tableComponents/Th.js`
export const ResizableUISortTH = styled(UISortTH).attrs({
  role: 'columnheader',
  scope: 'col'
}).withConfig({
  displayName: "StyledComponents__ResizableUISortTH",
  componentId: "ny0xjc-3"
})(["display:flex;flex-direction:row;flex:", " 0 auto;max-width:", "px;outline:none;padding:0 !important;width:", "px;&:last-of-type{max-width:unset !important;}[role='button']{cursor:", " !important;}", ""], props => props.width, props => props.width, props => props.width, props => props.disabled ? 'default' : 'inherit', tableCellFocusStyles);
export const TH = styled.th.attrs({
  'aria-disabled': false,
  tabIndex: 0
}).withConfig({
  displayName: "StyledComponents__TH",
  componentId: "ny0xjc-4"
})(["cursor:", ";", " ", ""], props => props.disabled ? 'default' : 'pointer', ({
  crmUI,
  resizable
}) => crmUI && !resizable && tableCellCrmUI, ({
  crmUI,
  resizable
}) => crmUI && !resizable && `
    &:last-of-type {
      border-right: none;
      max-width: unset !important;
    }`);

// Adapted from `customer-data-table/static/js/tableComponents/ResizeAffordance.js`
export const ResizeAffordance = styled.div.attrs({
  'data-test-id': 'resize-affordance'
}).withConfig({
  displayName: "StyledComponents__ResizeAffordance",
  componentId: "ny0xjc-5"
})(["border-right:1px solid ", ";background:none;margin-left:-10px;width:10px;cursor:col-resize;.hovered > &,&:hover{border-width:2px;border-color:", ";}"], GREAT_WHITE, CALYPSO);
// Adapted from `customer-data-table/static/js/tableComponents/ThDragDrop.js`
export const StyledContentOuterContainer = styled.div.withConfig({
  displayName: "StyledComponents__StyledContentOuterContainer",
  componentId: "ny0xjc-6"
})(["display:flex;flex-grow:1;height:100%;opacity:", ";background:", ";border:0 solid ", ";border-left-width:", "px;border-right-width:", "px;"], ({
  isDragging
}) => isDragging ? 0.5 : 1, ({
  isDragging
}) => isDragging ? GREAT_WHITE : undefined, CALYPSO, ({
  isOverToTheLeft
}) => isOverToTheLeft ? 2 : 0, ({
  isOverToTheRight
}) => isOverToTheRight ? 2 : 0);
export const StyledDragContentInnerContainer = styled(UIClickable).withConfig({
  displayName: "StyledComponents__StyledDragContentInnerContainer",
  componentId: "ny0xjc-7"
})(["display:flex;align-items:center;flex-grow:1;padding:12px 24px 12px 8px;"]);
export const StyledNoDragContentInnerContainer = styled(StyledDragContentInnerContainer).withConfig({
  displayName: "StyledComponents__StyledNoDragContentInnerContainer",
  componentId: "ny0xjc-8"
})(["padding-left:24px;"]);
export const StyledDragHandleContainer = styled.div.withConfig({
  displayName: "StyledComponents__StyledDragHandleContainer",
  componentId: "ny0xjc-9"
})(["display:flex;align-items:center;padding-left:1em;"]);

// See `ResizeAffordance` for `border-right` color
export const CheckboxTh = styled.th.attrs({
  'data-test-col-index': -1,
  'data-test-id': 'crm-table-bulk-action-cell-header',
  role: 'columnheader',
  scope: 'col',
  tabIndex: 0
}).withConfig({
  displayName: "StyledComponents__CheckboxTh",
  componentId: "ny0xjc-10"
})(["align-items:center;border-right:1px solid ", " !important;display:flex;justify-content:center;min-width:45px;outline:none;padding:0 !important;width:45px;", ""], GREAT_WHITE, tableCellFocusStyles);
export const CheckboxTd = styled.td.attrs({
  'data-test-col-index': -1,
  'data-test-id': 'crm-table-bulk-action-cell',
  role: 'cell',
  tabIndex: 0
}).withConfig({
  displayName: "StyledComponents__CheckboxTd",
  componentId: "ny0xjc-11"
})(["align-items:center;display:flex;justify-content:center;min-width:45px;outline:none;padding:0 !important;width:45px;", " ", ""], ({
  crmUI
}) => crmUI && tableCellCrmUI, tableCellFocusStyles);
/**
 * Shared td styles for apps using UITableHoverCell
 */
const tdStyle = css(["flex:", ";max-width:", ";outline:none;width:", ";", ""], ({
  width
}) => `${width}px 0 auto`, ({
  width
}) => `${width}px`, ({
  width
}) => `${width}px`, tableCellFocusStyles);
export const Td = styled.td.attrs({
  role: 'cell'
}).withConfig({
  displayName: "StyledComponents__Td",
  componentId: "ny0xjc-12"
})(["align-items:center;display:flex;flex-direction:row;outline:none;overflow:hidden !important;text-overflow:ellipsis !important;white-space:nowrap !important;&:last-of-type{max-width:unset !important;}", ""], tdStyle);
export const MultiLineString = styled(UIBreakString).withConfig({
  displayName: "StyledComponents__MultiLineString",
  componentId: "ny0xjc-13"
})(["white-space:normal;text-align:left;"]);