import { initParams, updateQueryAndPathParams, updateQueryParams
// @ts-expect-error not migrated to TS yet
} from 'EmailData/actions/params';
import { getHasAccessToEmailApprovalReadScope, getIsFreeEmail, getIsStarterEmail, getShouldDisableCreateEmailAction, getShouldUseBUPicker, getHasUsersTeamsAccess, getHasAssignAssetAccess
// @ts-expect-error not migrated to TS yet
} from 'EmailData/selectors/user';
// @ts-expect-error not migrated to TS yet
import { feedbackClientSelector } from 'EmailData/selectors/feedbackClient';
import { showPortalProbationWarningSelector, showPortalSuspensionSelector
// @ts-expect-error not migrated to TS yet
} from 'EmailData/portalStatus/selectors';
// @ts-expect-error not migrated to TS yet
import { getEmailCreditUsed } from 'EmailData/selectors/portalSendCreditsSelectors';

// @ts-expect-error not migrated to TS yet
import { selectIsInNewUserState } from 'email-management-lib/refactor/selectors/manage/isInNewUserState';
// @ts-expect-error not migrated to TS yet
import { selectCurrentFolderId } from 'email-management-lib/refactor/selectors/manage/foldersTable';
import { clearEmailSearch, maybeFetchOnNavigation
// @ts-expect-error not migrated to TS yet
} from 'email-management-lib/refactor/actions/general';
// @ts-expect-error not migrated to TS yet
import { setComparisonToolReturnLocation } from 'email-management-lib/refactor/actions/comparison/comparisonToolActions';

// import { hasYearInReviewExportSelector } from 'email-management-lib/refactor/yearInReview/selectors/yearInReviewSelectors';

import { connect } from 'react-redux';
import * as FilteringActions from 'email-management-lib/refactor/actions/manage/filtering';
import * as FolderActions from 'email-management-lib/refactor/actions/manage/folders';
import { enrollPortalForGettingStarted } from 'EmailData/actions/dashboardZeroState';
import { maybeFetchHasArchivedEmails, fetchSentEmailsCount, maybeFetchHasEmails, maybeFetchHasEmailsByCrmSearch, maybeFetchHasArchivedEmailsByCrmSearch } from 'email-management-lib/refactor/actions/manage/general';
import { clearSelectionAndBulkFlag } from 'email-management-lib/refactor/actions/manage/table';
import { getShowOnboardingTourPrompt } from 'email-management-lib/refactor/selectors/manage/onboarding';
import { getIsEmptyState, getIsEmptyStateLoaded } from 'email-management-lib/refactor/selectors/manage/emptyState';
import { getHasArchivedEmailsData, getHasEmailsData, getManageTable, getSelectedEmail, getSelectedUsersTeamsEmails, getSelectedEmailCrmObject } from 'email-management-lib/refactor/selectors/manage/table';
import { parseQueryParams } from 'EmailData/utils/url';
import manageEmailPrefetcher from 'email-management-lib/refactor/utils/performance/managePrefetcher';
import Manage from 'email-management-lib/refactor/components/manage/Manage';
import { selectState } from 'email-management-lib/refactor/actions/manage/filtering';
import { fetchApprovalsConfig } from 'EmailData/actions/approvals';
import { getIsMc0653Audience, getShowMc0653Variation } from 'email-management-lib/refactor/selectors/experiments';
const mapStateToProps = (state, ownProps) => {
  const [,, subsection] = ownProps.location.pathname.split('/');
  const tableState = getManageTable(state);
  const whitelistingOpen = subsection === 'whitelisting';
  const limitsOpen = subsection === 'limits';
  const listMode = subsection === 'folder' ? 'folder' : 'list';
  const currentFolderId = selectCurrentFolderId(state, ownProps);
  const currentSelectedEmailId = getSelectedEmail(state);
  const showPortalProbationWarning = showPortalProbationWarningSelector(state);
  const hasArchivedEmails = getHasArchivedEmailsData(state);
  const portalHasEmails = getHasEmailsData(state);
  const hasUsersAndTeamsAccess = getHasUsersTeamsAccess(state);
  const selectedUsersAndTeamsEmails = getSelectedUsersTeamsEmails(state);
  const shouldDisableCreateEmailAction = getShouldDisableCreateEmailAction(state);
  const showPortalSuspensionMessage = showPortalSuspensionSelector(state);
  const isInNewUserState = selectIsInNewUserState(state);
  const queryParams = parseQueryParams(ownProps.location.search);
  const showAssignAssetAccessPanel = Boolean(queryParams.requestedAssetId) && Boolean(queryParams.targetUserId) && getHasAssignAssetAccess(state);
  const visibleColumns = tableState.get('visibleColumns');
  const selectedCrmObject = getSelectedEmailCrmObject(state);
  const portalCreditsUsed = getEmailCreditUsed(state);
  const isMc0653Audience = getIsMc0653Audience(state);
  const showMc0653Variation = getShowMc0653Variation(state);
  return {
    autoStartOnboarding: selectIsInNewUserState(state) || !portalHasEmails,
    currentFolderId,
    hasArchivedEmails,
    hasUsersAndTeamsAccess,
    // hasYearInReviewExport: hasYearInReviewExportSelector(state),
    isEmptyStateLoaded: getIsEmptyStateLoaded(state),
    isEmptyState: getIsEmptyState(state),
    isFree: getIsFreeEmail(state),
    isInNewUserState,
    isStarter: getIsStarterEmail(state),
    hasAccessToEmailApprovalReadScope: getHasAccessToEmailApprovalReadScope(state),
    listMode,
    params: ownProps.match.params,
    portalHasEmails,
    query: queryParams,
    selectedCrmObject,
    selectedState: ownProps.match.params.state,
    selectedStateFilter: ownProps.match.params.state || queryParams.state || 'all',
    selectedUsersAndTeamsEmails,
    shouldDisableCreateEmailAction,
    shouldUseBUPicker: getShouldUseBUPicker(state),
    showAssignAssetAccessPanel,
    showOnboardingTourPrompt: getShowOnboardingTourPrompt(state),
    showPortalProbationWarning,
    showPortalSuspensionMessage,
    showSidepanel: currentSelectedEmailId > -1,
    whitelistingOpen,
    limitsOpen,
    feedbackClient: feedbackClientSelector(state),
    visibleColumns,
    portalCreditsUsed,
    isMc0653Audience,
    showMc0653Variation
  };
};
const mapDispatchToProps = Object.assign({}, FilteringActions, FolderActions, {
  enrollPortalForGettingStarted,
  fetchSentEmailsCount,
  initParams,
  fetchApprovalsConfig,
  maybeFetchOnNavigation,
  maybeFetchHasArchivedEmails,
  maybeFetchHasArchivedEmailsByCrmSearch,
  maybeFetchHasEmails,
  maybeFetchHasEmailsByCrmSearch,
  onClearEmailSearch: clearEmailSearch,
  updateQueryAndPathParams,
  updateQueryParams,
  selectState,
  setComparisonToolReturnLocation,
  clearSelectionAndBulkFlag
});
export default manageEmailPrefetcher(connect(mapStateToProps, mapDispatchToProps)(Manage));