'use es6';

import { createSelector } from 'reselect';
import { getHasAutomationGates, getIsUngatedForContextualAutomation } from 'EmailData/selectors/user';
import currentEmail from 'EmailData/selectors/details/current/currentEmail';
const automationForRegularEmailsCheck = (email, hasAutomationGates) => (email.isBatch() || email.isLocalTime() || email.isABEmail() || email.isScheduled()) && hasAutomationGates;
export const getCanLoadAutomationByEmail = email => createSelector([getIsUngatedForContextualAutomation, getHasAutomationGates], (isUngatedForContextualAutomation, hasAutomationGates) => {
  return email.isAvailableToWorkflows() && isUngatedForContextualAutomation || automationForRegularEmailsCheck(email, hasAutomationGates);
});
export const getCanUseAutomationOnRegularEmails = createSelector([currentEmail, getHasAutomationGates], automationForRegularEmailsCheck);
export const getCanLoadAutomation = createSelector([currentEmail, getIsUngatedForContextualAutomation, getCanUseAutomationOnRegularEmails], (email, isUngatedForContextualAutomation, canUseAutomationOnRegularEmails) => {
  return email.isAvailableToWorkflows() && isUngatedForContextualAutomation || canUseAutomationOnRegularEmails;
});