module.exports = {
  "revenuestagechangesubmit": {
    "name": "revenuestagechangesubmit",
    "class": "usage",
    "properties": {
      "stageName": "string"
    },
    "namespace": "customer-data-properties",
    "version": "1"
  },
  "Interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "action": {
        "type": "string"
      }
    },
    "namespace": "customer-data-properties",
    "version": "1"
  },
  "Pageview": {
    "name": "Pageview",
    "class": "view",
    "properties": {
      "screen": "string"
    },
    "namespace": "customer-data-properties",
    "version": "1"
  }
};