'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RESET_EMAIL_DETAILS } from 'EmailData/actions/actionTypes';
import { FETCH_CLICK_MAP_STATISTICS, FETCH_CLICK_MAP_STATISTICS_REQUEST } from 'email-management-lib/refactor/actions/actionTypes';
import { UNIQUE_LINKS_THRESHOLD } from 'email-management-lib/refactor/constants/details/detailsConstants';
import CampaignIdentifiedClicks from 'email-management-lib/refactor/models/CampaignIdentifiedClicks';
import ClickMapData from 'email-management-lib/refactor/models/ClickMapData';
const initialState = ImmutableMap({
  clickMapLinks: ImmutableMap({}),
  campaignIdentifiedClicks: ImmutableMap({}),
  isMaxLinksReached: false
});
function clickMapStatistics(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLICK_MAP_STATISTICS:
      {
        if (action.error) {
          return state;
        }
        return {
          clickMapLinks: List(action.payload.clickMapLinks.map(ClickMapData.from)),
          campaignIdentifiedClicks: ImmutableMap(action.payload.campaignClicks.map(campaign => {
            return [campaign.emailCampaignId, CampaignIdentifiedClicks.from(campaign, action.payload.ctaStats, action.payload.interactives)];
          })),
          isMaxLinksReached: action.payload.campaignClicks.some(campaign => campaign.uniquelyIdentifiedUrlClicks.length > UNIQUE_LINKS_THRESHOLD)
        };
      }
    case RESET_EMAIL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
export default asyncResource(clickMapStatistics, {
  requestActions: [FETCH_CLICK_MAP_STATISTICS_REQUEST],
  responseActions: [FETCH_CLICK_MAP_STATISTICS],
  invalidatingActions: [RESET_EMAIL_DETAILS]
});