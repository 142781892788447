import { COMPANY_WITHOUT_DEFAULTS_TAG_REGEX, CONTACT_WITHOUT_DEFAULTS_TAG_REGEX, QUERY_PARAM_BLACKLIST, QUERY_PARAM_BLACKLIST_PER_DOMAIN, HUBL_TEMPLATE_REGEX
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Clic... Remove this comment to see the full error message
} from 'ClickMapJS/lib/constants';
import { parseUrl, removeHostnameQueryParams, removeQueryParams, safeDecodeUrl
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Clic... Remove this comment to see the full error message
} from 'ClickMapJS/lib/urlUtils';
const ctaPathRegex = /https?:\/\/[^/]+\/cs\/ci\/?\?/i;
const ctaHostRegex = /https?:\/\/(eu1|na1)?.?(cta-image-cms2\.hubspot|hsctaimages)(?:qa)?\.(com|net)/i;
const webInteractiveHostRegex = /https?:\/\/(cta-(eu1|na1)|cta-service-cms2)\.hubspot(qa)?\.(com|net)/i;
const validGuidQueryParamRegex = /pg=([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/i;
const validWebInteractiveQueryParamRegex = /webInteractiveContentId=([0-9]+)/i;
export function escapeLinkRegex(str) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}
export function removeDuplicatedEqualParams(url) {
  // Split the url up
  const hashSplit = url.split('#');
  const paramsSplit = hashSplit[0].split('?');
  const params = paramsSplit[1] ? paramsSplit[1].split('&') : [];

  // Remove duplicated and equal params
  const newParams = params.reduce((accum, param, index) => {
    if (index + 1 <= params.length && !params.includes(param, index + 1)) {
      accum.push(param);
    }
    return accum;
  }, []);

  // Join the url back together.
  const paramsString = newParams.length > 0 ? `?${newParams.join('&')}` : '';
  const hashString = hashSplit[1] ? `#${hashSplit[1]}` : '';
  return `${paramsSplit[0]}${paramsString}${hashString}`;
}
export function removeNonCtaQueryParams(url) {
  const queryRegex = new RegExp('^.*?pg=[^&]+');
  const cleanedLink = queryRegex.exec(url);
  return cleanedLink ? cleanedLink[0] : url;
}
export function generateLinkRegexString(link) {
  const {
    hostname = ''
  } = parseUrl(link);
  const cleanedByHostUrl = removeHostnameQueryParams(link, hostname, QUERY_PARAM_BLACKLIST_PER_DOMAIN);
  const cleanedUrl = removeQueryParams(cleanedByHostUrl, QUERY_PARAM_BLACKLIST);
  const decodedCleanedUrl = safeDecodeUrl(cleanedUrl);
  const escapedCharactersLink = escapeLinkRegex(decodedCleanedUrl);
  const escapedPropertiesLink = escapedCharactersLink.replace(CONTACT_WITHOUT_DEFAULTS_TAG_REGEX, '[^&]*').replace(COMPANY_WITHOUT_DEFAULTS_TAG_REGEX, '[^&]*').replace(HUBL_TEMPLATE_REGEX, '[^&]*').replace(/(\\{\\{[^}]+\\}\\})+/g, '[^&]*').replace(/ /g, '\\+').replace(/^https?/, 'https?');
  const linkRegexString = escapedPropertiesLink.concat('$');
  return linkRegexString;
}
export function extractCtaGuidFromUrl(url) {
  const guidMatchResult = validGuidQueryParamRegex.exec(url);
  const isCta = ctaHostRegex.test(url) || ctaPathRegex.test(url);
  if (isCta && guidMatchResult) {
    return {
      valid: true,
      guid: guidMatchResult[1]
    };
  }
  return {
    valid: false
  };
}
export function extractWebInteractiveIdFromUrl(url) {
  const contentIdMatchResult = validWebInteractiveQueryParamRegex.exec(url);
  const isCta = webInteractiveHostRegex.test(url);
  if (isCta && contentIdMatchResult) {
    let params = url.split('?')[1];
    params = params.split('&');
    const redirectUrl = params.find(v => v.startsWith('redirectUrl'));
    return {
      valid: true,
      id: contentIdMatchResult[1],
      redirectUrl: redirectUrl ? redirectUrl.split('=')[1] : ''
    };
  }
  return {
    valid: false
  };
}
export function hydrateAndGetWebInteractiveLinks(linkList) {
  const ids = new Set();
  linkList.forEach(stats => {
    const isCta = extractWebInteractiveIdFromUrl(stats.link);
    if (isCta.valid) {
      stats.webInteractiveId = isCta.id;
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      ids[isCta.id] = true;
      ids.add(isCta.id);
      stats.redirectUrl = isCta.redirectUrl;
    }
    stats.isCta = isCta.valid;
  });
  return [...ids];
}
export function extractGuidsFromLinkList(linkList) {
  return linkList.reduce((guids, stats) => {
    const isCta = extractCtaGuidFromUrl(stats.link);
    if (isCta.valid) {
      stats.ctaGuid = isCta.guid;
      if (guids.indexOf(isCta.guid) === -1) {
        guids.push(isCta.guid);
      }
    }
    stats.isCta = isCta.valid;
    return guids;
  }, []);
}
export function generateClickMapLinkRegexList(linkList) {
  return linkList.reduce((linksRegex, clickMapLink) => {
    linksRegex.push(new RegExp(generateLinkRegexString(clickMapLink.link), 'i'));
    return linksRegex;
  }, []);
}