import { createSelector } from 'reselect';
import EmailTypes from 'EmailData/enums/EmailType';
import PqlUpgradeProduct from 'EmailData/enums/pqls/PqlUpgradeProduct';
import { allTrueFunctions } from 'EmailData/utils/functionUtils';
import { EMAIL_TYPE_SCOPES, getHasAccessToEmailType } from 'EmailData/helpers/EmailTypeHelpers';
export const createAuthItemSelector = (authItemsSelector, authItem) => createSelector(authItemsSelector, authItems => {
  return authItems.indexOf(authItem) > -1;
});
export const getEmailAuthSelectors = (getGates, getScopes) => {
  const getCanUseTransactional = createAuthItemSelector(getScopes, 'transactional-email-api');
  const emailTypesAuthedFor = scopes => {
    return Object.keys(EMAIL_TYPE_SCOPES).filter(emailType => getHasAccessToEmailType(scopes, emailType));
  };
  const hasAccessToAnEmailType = scopes => emailTypesAuthedFor(scopes).length > 0;
  const getHasAccessToSomeEmailType = createSelector([getScopes], hasAccessToAnEmailType);
  const getEmailTypesAuthedFor = createSelector([getScopes], emailTypesAuthedFor);
  const getHasEmailBlogOnlyAccess = createSelector([getEmailTypesAuthedFor], emailTypes => emailTypes.indexOf(EmailTypes.BLOG_EMAIL) > -1);
  const getHasEmailEditorAccess = createAuthItemSelector(getScopes, 'email-editor-access');
  const getHasCoreContentAccess = createAuthItemSelector(getScopes, 'content-core-api-access');
  const getHasMarketingEmailProduct = createAuthItemSelector(getScopes, 'email-marketing-product-access');
  const getHasNoBranding = createAuthItemSelector(getScopes, 'marketing-email-no-branding');
  const getHasEmailSendingStarter = createAuthItemSelector(getScopes, 'email-sending-starter');
  const getHasWorkflowsWriteAccess = createAuthItemSelector(getScopes, 'workflows-edit-team-owned');
  const getHasFormsWriteAccess = createAuthItemSelector(getScopes, 'forms-write');
  const getHasFullEditorAccess = createAuthItemSelector(getScopes, 'email-full-editing');
  const geHastWorkflowEditTeamOwnedScope = createAuthItemSelector(getScopes, 'workflows-edit-team-owned');
  const getHasEmailAccess = createAuthItemSelector(getScopes, 'email-access');
  const getHasFreeEmail = createSelector([getHasNoBranding, getHasEmailAccess], (hasNoBranding, hasEmailAccess) => !hasNoBranding && !hasEmailAccess);
  const getHasSoftLimitScope = createAuthItemSelector(getScopes, 'email-sending-soft-limit');
  const getHasEmailWriteScope = createAuthItemSelector(getScopes, 'email-write');
  const getHasEmailWriteAccess = scopes => scopes.includes('email-write');
  const usingEmailStarter = createSelector([getHasMarketingEmailProduct, getHasEmailSendingStarter, getHasEmailAccess], (hasMarketingEmailProduct, hasEmailSendingStarter, hasEmailAccess) => {
    return hasMarketingEmailProduct && hasEmailSendingStarter && !hasEmailAccess;
  });
  const getHasOnboarding = createSelector([getHasFreeEmail, usingEmailStarter], (isFreeEmail, isStarterEmail) => {
    return isFreeEmail || isStarterEmail;
  });
  const getCanUseLocalTime = createAuthItemSelector(getScopes, 'email-localtime');
  const getHasEmailWritePermissions = getHasEmailWriteScope;
  const getHasPermissionsToLoadEditor = createSelector([getHasMarketingEmailProduct, getHasEmailEditorAccess, getHasAccessToSomeEmailType], (hasMarketingEmailProduct, hasEmailEditorAccess, _hasAccessToAnEmailType) => {
    return hasEmailEditorAccess && (hasMarketingEmailProduct || _hasAccessToAnEmailType);
  });
  const getHasLitmusAccess = createSelector([getHasFullEditorAccess, getHasEmailWritePermissions], (fullEditorAccess, emailWritePermission) => {
    return fullEditorAccess && emailWritePermission;
  });
  const getCanHaveMultipleOfficeLocations = createAuthItemSelector(getScopes, 'multiple-office-locations');
  const getIsUngatedForSubscriptionGroups = createAuthItemSelector(getGates, 'subscriptions:groupSettings');
  const getHasDesignManagerAccess = createAuthItemSelector(getScopes, 'design-manager-access');
  const getPortalHasSavedTemplates = createSelector([getHasEmailAccess], hasScope => hasScope);
  const getCanSaveTemplates = createSelector([getHasDesignManagerAccess], hasScope => hasScope);
  const getPortalHasSavedLayouts = createSelector([getHasEmailAccess], hasScope => hasScope);
  const getCanSaveLayouts = createSelector([getHasDesignManagerAccess], hasScope => hasScope);
  const getHasContentAbTesting = createAuthItemSelector(getScopes, 'content-ab-testing');
  const getHasContentSettings = createAuthItemSelector(getScopes, 'content-settings-access');
  const getHasCampaignsAccess = createAuthItemSelector(getScopes, 'campaigns-access');
  const getHasCanSpamSettings = createAuthItemSelector(getScopes, 'can-spam-settings-access');
  const getHasContactsListAccess = createAuthItemSelector(getScopes, 'contacts-lists-access');
  const getHasEmailSelectRecipientsAccessScope = createAuthItemSelector(getScopes, 'email-select-recipients');
  const getHasEmailMultiSubscriptionsAccess = createAuthItemSelector(getScopes, 'email-multi-subscriptions-access');
  const getCanTestSendToAnyAddress = createAuthItemSelector(getScopes, 'email-test-sending-any-address');
  const getHasWorkflowsEmailAccessScope = createAuthItemSelector(getScopes, 'workflows-email-access');
  const _getHasBlogEmailAccess = createAuthItemSelector(getScopes, 'email-type-blog-access');
  const _getOldHasBlogEmailAccess = createAuthItemSelector(getScopes, 'email-batch-rss-blog');
  const getHasBlogEmailAccess = createSelector([getHasEmailBlogOnlyAccess, _getHasBlogEmailAccess, _getOldHasBlogEmailAccess], (isUngated, newBlogAccess, oldBlogAccess) => isUngated ? newBlogAccess : oldBlogAccess);
  const getHasRssEmailAccess = createAuthItemSelector(getScopes, 'email-type-rss-access');
  const getHasSupportAccessSelector = createAuthItemSelector(getScopes, 'zorse-support-inbox-access');

  //TODO remove from ContentEmailUI then safely remove here
  const getHasBlogRssAccess = allTrueFunctions([getHasBlogEmailAccess, getHasRssEmailAccess]);
  const getHasNewBlogRssAccess = allTrueFunctions([getHasBlogRssAccess]);
  const getHasDragDropAB = getHasContentAbTesting;
  const getHasFatigueSuppressionAccess = createAuthItemSelector(getScopes, 'email-recipient-fatigue-suppression-access');
  const canCreateDragDropEmail = (gates, scopes) => {
    const hasEmailEditorAccess = scopes.indexOf('email-editor-access') >= 0;
    if (!hasEmailEditorAccess) return false;
    const hasScopesForAnyEmailType = emailTypesAuthedFor(scopes).length > 0;
    return hasScopesForAnyEmailType;
  };
  const hasContextualAutomationGates = gates => {
    return gates.indexOf('ContextualAutomation:FormsEmailsAccess') > -1 && gates.indexOf('Email:ContextualAutomationV1') > -1;
  };
  const canCreateClassicEmail = scopes => {
    return scopes.indexOf('email-access') > -1;
  };
  const getCanCreateClassicEmail = createSelector([getScopes], canCreateClassicEmail);
  const getCanCreateDragDropEmail = createSelector([getGates, getScopes], canCreateDragDropEmail);
  const getHasAccessToBothEmailEditors = createSelector([getCanCreateClassicEmail, getCanCreateDragDropEmail], (_canCreateClassicEmail, _canCreateDragDropEmail) => _canCreateClassicEmail && _canCreateDragDropEmail);
  const getDragDropEmailAvailable = createSelector([getHasMarketingEmailProduct, getCanCreateDragDropEmail], (hasMarketingEmailProduct, canCreateDragDrop) => hasMarketingEmailProduct && canCreateDragDrop);
  const getHasCrmImportScope = createAuthItemSelector(getScopes, 'crm-import');
  const getHasListsWriteScope = createAuthItemSelector(getScopes, 'lists-edit');
  const getHasListsReadScope = createAuthItemSelector(getScopes, 'lists-view');
  const getHasContactsWriteScope = createAuthItemSelector(getScopes, 'contacts-write');
  const getPqlUpgradeProduct = createSelector([getHasFreeEmail, usingEmailStarter], (isFreeEmail, isEmailStarter) => {
    if (isEmailStarter) {
      return PqlUpgradeProduct.MARKETING_PROFESSIONAL;
    } else if (isFreeEmail) {
      return PqlUpgradeProduct.MARKETING_STARTER;
    }
    return PqlUpgradeProduct.MARKETING_ENTERPRISE;
  });
  const getHasMarketableContactsAccess = createAuthItemSelector(getScopes, 'marketable-contacts-access');
  const getIsUngatedForFreeTestSend = createAuthItemSelector(getGates, 'Email:AllowTestSendsInFree');
  const getCanUseTestSend = createSelector([getHasFreeEmail, getIsUngatedForFreeTestSend], (isFree, isUngatedForFreeTestSend) => !isFree || isUngatedForFreeTestSend);
  return {
    hasContextualAutomationGates,
    getCanUseTransactional,
    getHasEmailEditorAccess,
    getHasCoreContentAccess,
    getHasFullEditorAccess,
    getHasEmailWriteScope,
    getHasEmailWriteAccess,
    usingEmailStarter,
    getCanUseLocalTime,
    getHasEmailWritePermissions,
    getHasPermissionsToLoadEditor,
    getHasLitmusAccess,
    getCanHaveMultipleOfficeLocations,
    getHasMarketableContactsAccess,
    getHasContentAbTesting,
    getHasContentSettings,
    getHasCampaignsAccess,
    getHasAccessToSomeEmailType,
    getHasCanSpamSettings,
    getHasContactsListAccess,
    getHasBlogEmailAccess,
    getHasEmailSelectRecipientsAccessScope,
    getHasEmailMultiSubscriptionsAccess,
    getHasMarketingEmailProduct,
    getCanTestSendToAnyAddress,
    getHasDragDropAB,
    getIsUngatedForSubscriptionGroups,
    getPortalHasSavedTemplates,
    getPortalHasSavedLayouts,
    getCanSaveLayouts,
    getCanSaveTemplates,
    getHasDesignManagerAccess,
    getHasWorkflowsEmailAccessScope,
    getHasBlogRssAccess,
    getHasNewBlogRssAccess,
    getHasListsWriteScope,
    getHasListsReadScope,
    getHasContactsWriteScope,
    getEmailTypesAuthedFor,
    getHasReadOnlyFatigueSuppression: getHasFatigueSuppressionAccess,
    getHasFatigueSuppressionAccess,
    getHasFreeEmail,
    getHasOnboarding,
    getHasEmailAccess,
    canCreateDragDropEmail,
    canCreateClassicEmail,
    getHasAccessToBothEmailEditors,
    getCanCreateDragDropEmail,
    getDragDropEmailAvailable,
    getCanCreateClassicEmail,
    getHasCrmImportScope,
    getPqlUpgradeProduct,
    getHasEmailBlogOnlyAccess,
    getHasSupportAccessSelector,
    getHasSoftLimitScope,
    geHastWorkflowEditTeamOwnedScope,
    getIsUngatedForFreeTestSend,
    getCanUseTestSend,
    getHasWorkflowsWriteAccess,
    getHasFormsWriteAccess
  };
};