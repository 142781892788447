import Raven from 'raven-js';
import { mapValidateBatchToStageValidation } from './pipelineValidationUtils';

// If the fetch for validations ever fails (either for the record or pipeline), we fail open.
// We do this by returning null in the catch block, which is handled internally by isTransitionValid to always return true
const FALLBACK_ALLOW_ALL_TRANSITIONS = null;
export const getFetchValidationForRecordV2 = ({
  httpClient,
  objectTypeId,
  validationRequest
}) => {
  const body = {
    //We are using client translations as consolidate
    //error messages to one message
    includeTranslations: false,
    objectValidations: validationRequest
  };
  return httpClient.post(`pipelines-validation/v2/validate/objects/batch`, {
    data: body
  }).then(response => {
    if (!response || !response.objects) {
      throw new Error('Failed to return validations');
    }
    const results = response.objects;

    // The BE giving us an empty array signals that the object type does not support governance/approvals and we should allow all transitions.
    // https://hubspot.slack.com/archives/C025K57CMMG/p1710427382439889
    if (results.length === 0) {
      return FALLBACK_ALLOW_ALL_TRANSITIONS;
    }
    return mapValidateBatchToStageValidation(objectTypeId, results);
  }).catch(error => {
    Raven.captureException(error, {
      extra: {
        objectTypeId,
        objectId: validationRequest.length && validationRequest[0].objectId
      }
    });
    return FALLBACK_ALLOW_ALL_TRANSITIONS;
  });
};