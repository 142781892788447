import PropTypes from 'prop-types';
import { PLACEMENTS } from 'UIComponents/tooltip/PlacementConstants';
import domElementPropType from 'UIComponents/utils/propTypes/domElement';
const placements = ['top left', 'left top', 'top right', 'right top', 'bottom right', 'right bottom', 'bottom left', 'left bottom'];
// copied from UIComponents library
const abstractFloatyProps = {
  target: domElementPropType,
  zIndex: PropTypes.number
};
const floatyWrapperWithoutChildrenPropTypes = {
  placement: PropTypes.oneOf(placements),
  onTipRender: PropTypes.func,
  onTipUnmount: PropTypes.func
};
export const floatyWrapperPropTypes = Object.assign({}, abstractFloatyProps, floatyWrapperWithoutChildrenPropTypes);
const coachingTipButtonWithoutTargetPropTypes = Object.assign({
  onTipClick: PropTypes.func.isRequired,
  tipId: PropTypes.string.isRequired,
  tipIndex: PropTypes.number.isRequired
}, floatyWrapperWithoutChildrenPropTypes);
export const coachingTipButtonPropTypes = Object.assign({
  target: floatyWrapperPropTypes.target
}, coachingTipButtonWithoutTargetPropTypes);
export const coachingTipPropTypes = Object.assign({
  actionText: PropTypes.node,
  attachTo: PropTypes.string.isRequired,
  fallbackElementQuery: PropTypes.string,
  cta: PropTypes.exact({
    handler: PropTypes.func,
    href: PropTypes.string,
    text: PropTypes.node
  }),
  isOpen: PropTypes.bool.isRequired,
  onHideTip: PropTypes.func.isRequired,
  onTurnOffClick: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.exact({
    description: PropTypes.node,
    content: PropTypes.node
  }).isRequired).isRequired,
  title: PropTypes.node.isRequired,
  popoverPlacement: PropTypes.oneOf(PLACEMENTS)
}, coachingTipButtonWithoutTargetPropTypes);
export const uiCoachingTipspropTypes = {
  _demonstration: PropTypes.bool,
  config: PropTypes.exact({
    id: PropTypes.string.isRequired,
    tips: PropTypes.arrayOf(PropTypes.exact(Object.assign({}, floatyWrapperPropTypes, {
      tipId: PropTypes.string,
      actionText: coachingTipPropTypes['actionText'],
      attachTo: coachingTipPropTypes['attachTo'],
      fallbackElementQuery: coachingTipPropTypes['fallbackElementQuery'],
      cta: coachingTipPropTypes['cta'],
      steps: coachingTipPropTypes['steps'],
      title: coachingTipPropTypes['title'],
      popoverPlacement: coachingTipPropTypes['popoverPlacement']
    })).isRequired).isRequired
  }).isRequired
};