'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import { MIN_CLICKS_CUTTOFF_WHEN_THROTTLED, UNIQUE_LINKS_THRESHOLD } from 'email-management-lib/refactor/constants/details/detailsConstants';
import { extractCtaGuidFromUrl, extractWebInteractiveIdFromUrl, removeDuplicatedEqualParams } from 'email-management-lib/refactor/utils/details/ctasUtil';
import { safeDecodeUrl } from 'ClickMapJS/lib/urlUtils';
class CampaignIdentifiedClicks {
  static from(json, ctaData, interactiveData) {
    let uniquelyIdentifiedUrlClicks = json.uniquelyIdentifiedUrlClicks;

    // If more than threshold links, only process those with more than cutoff clicks for perf reasons.
    if (json.uniquelyIdentifiedUrlClicks.length > UNIQUE_LINKS_THRESHOLD) {
      uniquelyIdentifiedUrlClicks = json.uniquelyIdentifiedUrlClicks.filter(li => li.clicks > MIN_CLICKS_CUTTOFF_WHEN_THROTTLED);
    }
    return new List(uniquelyIdentifiedUrlClicks.map(identifiedClick => {
      const cleanedUrl = removeDuplicatedEqualParams(identifiedClick.url);
      const decodedCleanedUrl = safeDecodeUrl(cleanedUrl);
      const ctaGuid = extractCtaGuidFromUrl(identifiedClick.url);
      if (ctaGuid.valid) {
        const linkCta = ctaData.find(cta => {
          return cta && cta.guid === ctaGuid.guid;
        });
        if (!linkCta) {
          return ImmutableMap(Object.assign({}, identifiedClick, {
            url: decodedCleanedUrl
          }));
        }
        return ImmutableMap(Object.assign({}, identifiedClick, {
          url: decodedCleanedUrl,
          displayName: linkCta.name
        }));
      }
      const webInteractiveContentId = extractWebInteractiveIdFromUrl(identifiedClick.url);
      if (webInteractiveContentId.valid) {
        const linkCta = interactiveData[webInteractiveContentId.id];
        if (!linkCta) {
          return ImmutableMap(Object.assign({}, identifiedClick, {
            url: decodedCleanedUrl
          }));
        }
        return ImmutableMap(Object.assign({}, identifiedClick, {
          url: decodedCleanedUrl,
          displayName: linkCta.name
        }));
      }
      return ImmutableMap(Object.assign({}, identifiedClick, {
        url: decodedCleanedUrl
      }));
    }));
  }
}
export default CampaignIdentifiedClicks;