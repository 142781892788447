import identity from 'transmute/identity';
import { InMemoryCache } from '../../cache/InMemoryCache';
import { fetchConditionalPropertyRules } from './api';
export const makeConditionalPropertyRulesCache = () => new InMemoryCache(fetchConditionalPropertyRules, identity);

// CP rules are not expected to change during the lifetime of a single page in
// the app and are safe to durably cache
export const cache = makeConditionalPropertyRulesCache();

// exported for unit test usage only
export const _resetCache = () => {
  cache.clear();
};