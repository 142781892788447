module.exports = {
  "userInteraction": {
    "name": "user interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "coachingTipId": {
        "type": "string"
      },
      "tipId": {
        "type": "string"
      }
    },
    "namespace": "coaching-tips",
    "version": "1"
  }
};