import { getPropertyResolver } from 'reference-resolvers-lite/utils/getPropertyResolver';
import { getAnalyticsSourcePropertyReferenceType, isResolveableAnalyticsSourceProperty } from './analyticsSourcePropertyUtils';
import { createCachedCustomResolver, isCustomReferenceType } from './createCachedCustomResolver';
import { createStandardCachedResolver } from 'reference-resolvers-lite/utils/createStandardCachedResolver';
import { getCustomReferenceTypeForProperty } from './getCustomReferenceTypeForProperty';
import { isFile } from 'customer-data-objects/property/PropertyIdentifier';
import { FILE_ID_OR_URL } from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';
import { FILE_TYPE_ENHANCED_UX_GATE } from '../files/constants';
const getCustomResolverForProperty = (property, objectTypeId, {
  httpClient
}) => {
  const customReferenceType = getCustomReferenceTypeForProperty(property, objectTypeId);
  if (customReferenceType) {
    return createCachedCustomResolver(customReferenceType, objectTypeId, {
      httpClient
    });
  }
  return undefined;
};
const getAnalyticsSourcePropertyResolver = (property, objectTypeId, objectId, value, getAdditionalPropertyValue, {
  httpClient
}) => {
  const referenceType = getAnalyticsSourcePropertyReferenceType(property, objectTypeId, objectId, value, getAdditionalPropertyValue);
  if (!referenceType) {
    return undefined;
  }
  if (isCustomReferenceType(referenceType)) {
    return createCachedCustomResolver(referenceType, objectTypeId, {
      httpClient
    });
  }
  return createStandardCachedResolver(referenceType, objectTypeId, {
    httpClient
  });
};
const getFileTypePropertyResolver = (__property, objectTypeId, {
  httpClient
}) => {
  return createStandardCachedResolver(FILE_ID_OR_URL, objectTypeId, {
    httpClient
  });
};

/**
 * Until all CRM property definitions contain the
 * `externalOptionsReferenceType` field, use this function to
 * map properties (given an objectTypeId) to the right resolver.
 *
 * Note: this only works with objectTypeIds with metaTypeId=0.
 * This is because objectTypeId is not the same in PROD and QA
 * for all other metaTypeIds.
 *
 * @param property CRM property definition object
 * @param objectTypeId Object Type ID in the form `metaTypeId-typeId`
 * (i.e. 0-1)
 * @param httpClient httpClient to forward to the reference resolver
 * NOTE: the `httpClient` option only applies the first time a resolver is
 * requested for a `objectTypeId/referenceType` pair. Subsequent calls will
 * return the cached resolver instead.
 *
 * @returns A ReferenceResolver to use for resolving values of the given
 * property or `undefined` if no resolver is available for the property
 */
export const getPropertyComponentResolver = options => {
  const {
    getAdditionalPropertyValue,
    getIsUngated,
    httpClient,
    objectId,
    objectTypeId,
    property,
    value
  } = options;
  const customResolver = getCustomResolverForProperty(property, objectTypeId, {
    httpClient
  });

  // We have some hard coded resolvers on the frontend while we're moving
  // everything to external options. These take precendent over the external
  // options resolvers.
  if (customResolver) {
    return customResolver;
  }

  // Analytics source properties cannot be handled by getPropertyResolver
  // because they are dependent on the value of other properties on the object
  // so we have to handle them in a separate case here
  if (isResolveableAnalyticsSourceProperty(property)) {
    return getAnalyticsSourcePropertyResolver(property, objectTypeId, objectId, value, getAdditionalPropertyValue, {
      httpClient
    });
  }

  // As of Jan 2024, file type properties do not have externalOptionsReferenceType set in their property definition
  // We are hardcoding a resolver for file type properties here until property definitions are updated
  // Property definitions are getting updated as a part of #external-options-cleanup-group
  // https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1560
  if (isFile(property) && getIsUngated(FILE_TYPE_ENHANCED_UX_GATE)) {
    return getFileTypePropertyResolver(property, objectTypeId, {
      httpClient
    });
  }
  return getPropertyResolver({
    property,
    objectTypeId,
    httpClient
  }) || undefined;
};