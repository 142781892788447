'use es6';

import Email from 'EmailData/models/Email';
import Template from 'EmailData/models/Template';
import { AB_METRIC_TYPES } from 'EmailData/constants/details/abEmailConstants';
import { canCancel, canForceCancel, getAbMasterCampaignId, getAbVariantCampaignId, getDefaultEmail, getHoursToWait, getTestPercentage, isDraft, isProcessingCancelled, isWaitingForWinner, isWinnerCanceled } from 'EmailData/utils/details/abEmailUtil';
import { getAutomatedWinningCampaignId, getDefaultWinningAutomatedEmailType, getDeprecatedCampaignId, getTestTimes, getWinningAutomatedEmail, getWinningAutomatedEmailType, getWinningAutomatedVariantType, hasAutomatedWinner, isAutomatedEmailWinner, getCampaignGroupId, getHasPublishedAutomatedAbTest } from 'EmailData/utils/details/abAutomatedEmailUtil';
import AbEmailStreamCampaign from 'EmailData/models/details/children/AbEmailStreamCampaign';
import BaseEmailDetail from 'EmailData/models/details/BaseEmailDetail';
const abDetailDefaults = {
  campaignGroupId: null,
  variantEmailId: null,
  variantEmail: Email.from({}),
  variantTemplateInfo: Template.from({}),
  emailABStreamCampaign: AbEmailStreamCampaign.from({})
};
class AbAutomatedEmailDetail extends BaseEmailDetail(Object.assign({}, abDetailDefaults)) {
  static from(json) {
    const response = super.from(json);
    if (typeof response.emailABStreamCampaign !== 'undefined') {
      response.emailABStreamCampaign = AbEmailStreamCampaign.from(response.emailABStreamCampaign);
    }
    if (response.campaignGroup && response.campaignGroup.id) {
      response.campaignGroupId = response.campaignGroup.id;
    }
    if (typeof response.variant !== 'undefined') {
      response.variantEmailId = response.variant.id;
      response.variantEmail = Email.from(Object.assign({}, response.variant));
    }
    if (typeof response.variantTemplateInfo !== 'undefined') {
      response.variantTemplateInfo = Template.from(Object.assign({}, response.variantTemplateInfo));
    }
    return new AbAutomatedEmailDetail(response);
  }
  isSampleSizeInsignificant() {
    return false;
  }
  isSampling() {
    return false;
  }
  isWinnerCanceled() {
    return isWinnerCanceled(this);
  }
  isProcessingCancelled() {
    return isProcessingCancelled(this);
  }
  isDraft() {
    return isDraft(this);
  }
  isWaitingForWinner() {
    return isWaitingForWinner(this);
  }
  canCancel() {
    return canCancel(this);
  }
  canForceCancel() {
    return canForceCancel(this);
  }
  isEvenlySplitSend() {
    return true;
  }
  hasWinner() {
    return hasAutomatedWinner(this);
  }
  hasFallback() {
    return false;
  }
  getHasPublishedAutomatedAbTest() {
    return getHasPublishedAutomatedAbTest(this);
  }
  getWinningCampaignId() {
    return getAutomatedWinningCampaignId(this);
  }
  getWinningCampaignIds() {
    return this.get('emailABStreamCampaign').get('winnerCampaignIds');
  }
  getAbMasterCampaignId() {
    return getAbMasterCampaignId(this);
  }
  getAbVariantCampaignId() {
    return getAbVariantCampaignId(this);
  }
  getWinnerExecTime() {
    return null;
  }
  isEmailWinner(versionType) {
    return isAutomatedEmailWinner(this, versionType);
  }
  isEmailFallback() {
    return false;
  }
  isSamplingInsignificant() {
    return false;
  }
  getWinningEmailType() {
    return getWinningAutomatedEmailType(this);
  }
  getWinningVariantType() {
    return getWinningAutomatedVariantType(this);
  }
  getWinningEmail() {
    return getWinningAutomatedEmail(this);
  }
  getTestMetric() {
    return AB_METRIC_TYPES.NONE;
  }
  getTestPercentage() {
    return getTestPercentage(this);
  }
  getHoursToWait() {
    return getHoursToWait(this);
  }
  isInconclusive() {
    return false;
  }
  getDefaultWinningEmailType() {
    return getDefaultWinningAutomatedEmailType(this);
  }
  getDefaultEmail() {
    return getDefaultEmail(this);
  }
  getVariantTemplateInfo() {
    return this.variantTemplateInfo;
  }
  getLastTestPrimaryEmail() {
    if (this.isDraft() || !this.hasWinner()) {
      return this.primaryEmail;
    }
    return this.emailABStreamCampaign.variantVersion === 'A' ? this.primaryEmail : this.variantEmail;
  }
  getLastTestPrimaryEmailCampaignId() {
    return this.getLastTestPrimaryEmail().primaryEmailCampaignId;
  }
  getLastTestPrimaryEmailId() {
    return this.getLastTestPrimaryEmail().id;
  }
  getLastTestVariantEmail() {
    if (this.isDraft() || !this.hasWinner()) {
      return this.variantEmail;
    }
    return this.emailABStreamCampaign.variantVersion === 'A' ? this.variantEmail : this.primaryEmail;
  }
  getLastTestVariantEmailCampaignId() {
    return this.getLastTestVariantEmail().primaryEmailCampaignId;
  }
  getLastTestVariantEmailId() {
    return this.getLastTestVariantEmail().id;
  }
  getTestTimes() {
    return getTestTimes(this);
  }
  getCampaignGroupId() {
    return getCampaignGroupId(this);
  }
  getDeprecatedCampaignId() {
    return getDeprecatedCampaignId(this);
  }
  getDeprecatedMasterCampaignId() {
    if (this.emailABStreamCampaign.variantVersion === 'A') {
      return getDeprecatedCampaignId(this);
    }
    return this.getLastTestPrimaryEmailCampaignId();
  }
  getDeprecatedVariantCampaignId() {
    if (!this.emailABStreamCampaign.variantVersion || this.emailABStreamCampaign.variantVersion === 'A') {
      return this.getLastTestVariantEmailCampaignId();
    }
    return getDeprecatedCampaignId(this);
  }
}
export default AbAutomatedEmailDetail;