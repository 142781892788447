import { CREATE_INPUT_MODE, EDIT_INPUT_MODE } from '../../../v2/types/PropertyInputV2Component';
import { getFetchValidationForRecordV2 } from './pipelineValidationFetchers';
import { InMemoryCache } from '../../../cache/InMemoryCache';
const fetchPipelineStageValidation = ({
  objectTypeId,
  inputMode,
  pipelineId,
  stageIds,
  objectId
}, httpClient) => {
  const isInEditOrCreateMode = inputMode === EDIT_INPUT_MODE || inputMode === CREATE_INPUT_MODE;

  // NOTE: We want to make sure other input modes aren't subject to validation here such as bulk actions since it performs its own validations.
  if (!isInEditOrCreateMode) {
    return Promise.resolve(null);
  }
  const parsedObjectId = objectId && inputMode === EDIT_INPUT_MODE ? parseInt(objectId, 10) : undefined;

  //If a pipeline is present, we can send that pipelineId to the BE which will validate ALL the stages in the pipeline
  if (pipelineId) {
    return getFetchValidationForRecordV2({
      httpClient,
      objectTypeId,
      validationRequest: [{
        targetPipelineId: pipelineId,
        objectTypeId,
        objectId: parsedObjectId
      }]
    });
  }

  // If there is no pipeline, but we have a list of stages, we can build a batch validation body that will validate each target stage.
  // This is typical for the custom object case where they aren't always guaranteed to be in a pipeline.
  // We can choose to validate the current page of stages in the dropdown (which may be across multiple pipelines).
  if (stageIds && stageIds.length) {
    return getFetchValidationForRecordV2({
      httpClient,
      objectTypeId,
      validationRequest: stageIds.map(stageId => ({
        targetStageIds: [stageId],
        objectTypeId,
        objectId: parsedObjectId
      }))
    });
  }
  return Promise.resolve(null);
};
const getCacheKey = ({
  pipelineId,
  objectTypeId,
  inputMode,
  objectId,
  stageIds
}) => {
  return `${objectTypeId}/${pipelineId}/${inputMode}/${objectId}/${JSON.stringify(stageIds)}`;
};
export const StageValidationCache = new InMemoryCache(fetchPipelineStageValidation, getCacheKey);

// exported for unit test usage only
export const _resetCache = () => {
  StageValidationCache.clear();
};