/* eslint-disable @typescript-eslint/no-floating-promises */

import { UserAttributesClient } from 'frontend-preferences-client';
const userAttributesClient = UserAttributesClient.forCaller('ContentEmailUI');
export const HAS_DISMISSED_MOBILE_BANNER_KEY = 'hasDismissedMobileBanner';
export const MOBILE_BANNER_VIEW_TIMES = 'mobileBannerViewTimes';
export const HAS_SEEN_EMAIL_FRAMEWORK_ONBOARDING_KEY = 'hasSeenEmailFrameworkOnboarding';
export const SEEN_EMAIL_FRAMEWORK_ONBOARDING_TIME_KEY = 'seenEmailFrameworkOnboardingTime';
export function setUserAttributeRequest(attributeName, attributeValue) {
  return userAttributesClient.write(attributeName, String(attributeValue));
}
export function getUserAttributesRequest({
  keys
}) {
  const fetchBody = keys.reduce((acc, key) => {
    return Object.assign({}, acc, {
      [key]: ''
    });
  }, {});
  return userAttributesClient.batchFetch(fetchBody);
}
export function updateUserHasSeenEmailFrameworkOnboardingRequest(hasSeenEmailFrameworkOnboarding = true) {
  if (hasSeenEmailFrameworkOnboarding) {
    userAttributesClient.write(SEEN_EMAIL_FRAMEWORK_ONBOARDING_TIME_KEY, String(Date.now()));
  }
  return userAttributesClient.write(HAS_SEEN_EMAIL_FRAMEWORK_ONBOARDING_KEY, String(hasSeenEmailFrameworkOnboarding));
}
export function updateUserHasDismissedMobileBannerRequest() {
  return userAttributesClient.write(HAS_DISMISSED_MOBILE_BANNER_KEY, 'true');
}
export function updateMobileBannerViewTimes(count) {
  return userAttributesClient.write(MOBILE_BANNER_VIEW_TIMES, String(count));
}