'use es6';

import { useSelector } from 'react-redux';
import { getHasDismissedOnboarding } from 'email-management-lib/refactor/selectors/manage/onboarding';
import { getHasOnboarding } from 'EmailData/selectors/user';
function useDashboardOnboarding() {
  const hasOnboarding = useSelector(getHasOnboarding);
  const hasDismissedOnboarding = useSelector(getHasDismissedOnboarding);
  return {
    hasOnboarding,
    hasDismissedOnboarding
  };
}
export default useDashboardOnboarding;